'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./Webplayer').default} Playbar
 */

import ClassLogger from 'ClassLogger';

export default class ProgressBar {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'ProgressBar';
    }

    /**
     * @param {Playbar} playbar
     */
    constructor (playbar) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.playbar = playbar;

        /** @type {NodeJS.Timer} */
        this.onTimeUpdate = undefined;

        /** @type {HTMLInputElement} */
        this.progressRangeSlider = document.querySelector('[data-abywebplayer-progressbar]');

        /** @type {HTMLParagraphElement} */
        this.currentTime = document.querySelector('[data-abywebplayer-currenttime]');

        /** @type {HTMLParagraphElement} */
        this.totalDuration = document.querySelector('[data-abywebplayer-totalduration]');

        window.addEventListener('beforeunload', () => {
            this.stop();
        });
    }

    /**
     * @returns {this}
     */
    init () {
        this.logger.log('Initializing progress bar');

        return this
            .initProgressListener()
            .initProgressBarRangeSlider();
    }

    /**
     * @returns {this}
     *
     * @private
     */
    initProgressListener () {
        this.stop();

        this.onTimeUpdate = setInterval(() => {
            this.repaint();
        }, 1000);

        return this;
    }

    /**
     * @returns {this}
     */
    initProgressBarRangeSlider () {
        this.progressRangeSlider.addEventListener('input', event => {
            this.adjustProgressBarRangeSlider(event);
        });

        return this;
    }

    /**
     * Save the given volume `level` to local storage.
     *
     * @param {CustomEvent|Event} event
     *
     * @returns {this}
     */
    adjustProgressBarRangeSlider (event) {
        const { value, min, max } = event.target;

        this.progressRangeSlider.classList.add('u-quick-transition');

        const backgroundSize = (value - min) * 100 / (max - min).toPrecision(1);

        // seek to current time
        this.playbar.assignProgressInSeconds(value / 100 * this.totalDurationInSeconds());

        this.updateProgressBar(backgroundSize);

        setTimeout(() => {
            this.progressRangeSlider.classList.remove('u-quick-transition');
        }, 250);

        return this;
    }

    /**
     * Repaint the current play values in the UI.
     *
     * @returns {this}
     */
    repaint () {
        return this
            .updateTotalDuration()
            .updateCurrentTime()
            .updateProgressBar();
    }

    /**
     * Clear the time update interval that repaints the UI.
     *
     * @returns {this}
     */
    stop () {
        clearInterval(this.onTimeUpdate);

        return this;
    }

    /**
     * Returns the total duration in seconds.
     *
     * @returns {Number}
     */
    totalDurationInSeconds () {
        return this.playbar.audioElement.duration;
    }

    /**
     * Update the total duration in the UI.
     *
     * @returns {this}
     */
    updateTotalDuration () {
        this.totalDuration.textContent = this.formatAsDuration(this.totalDurationInSeconds());

        return this;
    }

    /**
     * Returns the currently played time in seconds.
     *
     * @returns {Number}
     */
    currentTimeInSeconds () {
        return this.playbar.audioElement.currentTime;
    }

    /**
     * Update the currently played time in the UI.
     *
     * @returns {this}
     */
    updateCurrentTime () {
        this.currentTime.textContent = this.formatAsDuration(this.currentTimeInSeconds());

        return this;
    }

    /**
     * Update the range slider value and background size.
     *
     * @param {Number | undefined} sizeInPercent
     *
     * @returns {this}
     */
    updateProgressBar (sizeInPercent) {
        const currentProgressInPercent = this.currentTimeInSeconds() / this.totalDurationInSeconds() * 100;
        sizeInPercent = sizeInPercent || parseFloat(currentProgressInPercent.toFixed(3));

        this.progressRangeSlider.style.backgroundSize = `${sizeInPercent}% 100%`;
        this.progressRangeSlider.value = sizeInPercent;

        return this;
    }

    /**
     * Returns a formatted timestamp based on the given value in `seconds`.
     *
     * @param {Number}
     *
     * @returns {string}
     *
     * @private
     *
     * @example
     * ```js
     * this.formatAsDuration(15)
     * // 0:15
     *
     * this.formatAsDuration(60)
     * // 1:00
     *
     * this.formatAsDuration(612)
     * // 10:12
     * ```
     */
    formatAsDuration (valueInSeconds) {
        const hours = Math.floor(valueInSeconds / 3600);
        let minutes = Math.floor((valueInSeconds - (hours * 3600)) / 60);
        let seconds = Math.floor(valueInSeconds - (hours * 3600) - (minutes * 60));

        if (seconds < 10) {
            seconds = '0' + seconds;
        }

        if (hours === 0) {
            return `${minutes}:${seconds}`;
        }

        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return `${hours}:${minutes}:${seconds}`;
    }
}
