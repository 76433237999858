'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';

class Tabs {
    getClassName () {
        return 'Tabs';
    }

    /**
     * Create a new instance.
     * @param {NavigationHandler} navigationHandler
     */
    constructor (navigationHandler) {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        this.navigationHandler = navigationHandler;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    init () {
        const self = this;

        self.tabcomponents = document.querySelectorAll('[data-tabcomponent]');
        if (self.tabcomponents.length === 0) {
            return;
        }

        self.tabcomponents.forEach((tabcomponent) => {
            self.tabs = tabcomponent.querySelectorAll('[aria-selected]');
            self.tabpanels = tabcomponent.querySelectorAll('[aria-expanded]');

            self.tabs.forEach((tab) => {
                tab.addEventListener('click', () => {
                    self.reset();
                    const ariaControls = tab.getAttribute('aria-controls');
                    tab.setAttribute('aria-selected', true);
                    document.getElementById(ariaControls).setAttribute('aria-expanded', true);
                });
            });
        });
    }

    reset () {
        const self = this;
        self.tabs.forEach((tab) => {
            tab.setAttribute('aria-selected', false);
        });

        self.tabpanels.forEach((tabpanel) => {
            tabpanel.setAttribute('aria-expanded', false);
        });
    }
}

export default Tabs;
