'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';

export default class Clipboard {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'Clipboard';
    }

    /**
     * @param {NavigationHandler} navigationHandler
     */
    constructor (navigationHandler) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.navigationHandler = navigationHandler;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    /**
     * Initialize the CopyButton.
     *
     * @returns {this}
     */
    init () {
        const copybuttons = document.querySelectorAll('[data-copybutton]');

        if (!copybuttons.length) {
            return;
        }

        // hide copybutton if no Browser support
        if (!navigator.clipboard.writeText) {
            copybuttons.forEach(copybutton => {
                copybutton.classList.add('u-display-none');
            });
            return;
        }

        copybuttons.forEach(copybutton => {
            copybutton.addEventListener('click', (e) => {
                e.preventDefault();

                const copyText = copybutton.dataset.shareurl;
                const modal = document.createElement('div');
                modal.innerHTML = '<p>Kopiert!</p>';
                modal.classList.add('u-modal');

                navigator.clipboard.writeText(copyText).then(() => {
                    // prevent mtpl modals by disabling button until modal has faded out and was removed from DOM again
                    copybutton.after(modal);
                    setTimeout(function () {
                        modal.classList.add('u-fade-out');
                    }, 500);
                    setTimeout(function () {
                        modal.remove();
                    }, 2000);
                });
            });
        });
        return this;
    }
}
