'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';

class BrushHeadlines {
    /**
     * Returns the log prefix.
     */
    getClassName () {
        return 'BrushHeadlines';
    }

    /**
     * Create a new instance.
     *
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     */
    constructor (commonMethods, navigationHandler) {
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;

        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        if (document.documentElement.classList.contains('template--rockantenne') === false) {
            return;
        }

        this.navigationHandler
            .on('ready', () => {
                this.assignBrushBackgroundToHeadlines();
                this.registerOnWindowResizeListener();
            })
            .on('render', () => {
                this.assignBrushBackgroundToHeadlines();
            });
    }

    /**
     * Register a `resize` listener on the window that re-renders the brush
     * background for selected headlines when the browser window is resized.
     *
     * @returns {this}
     */
    registerOnWindowResizeListener () {
        this.logger.log('Registering window "resize" listener');

        window.addEventListener('resize', () => {
            this.commonMethods.debounce(() => {
                this
                    .removeBrushBackgroundFromHeadlines()
                    .assignBrushBackgroundToHeadlines();
            }, 200)();
        });

        return this;
    }

    /**
     * Render brush backgrounds for individual headlines and make sure
     * multiline headlines receive their own brush backgrounds.
     */
    assignBrushBackgroundToHeadlines () {
        const headlines = this.headlinesRequiringBrushBackground();

        this.logger.log(`Adding brush background to ${headlines.length} headline(s)`);

        headlines.forEach(headline => {
            this.addBrushBackgroundTo(headline);
        });
    }

    /**
     * Returns all headlines that should have a brush background.
     *
     * @returns {NodeList[]}
     */
    headlinesRequiringBrushBackground () {
        return document.querySelectorAll('[data-brush]');
    }

    /**
     * The process to add brush backgrounds is the following:
     *
     *   1. wrap each word of the headline in a <span> tag
     *   2. go through each word (wrapped in a span) of the headline and concat those words on the same row
     *   3. join all words in the same row and wrap that text in a <span> that carries the brush background
     *   4. assign the created HTML for the headline to the given `element`. And we’re done.
     *
     * @param {Element} element
     */
    addBrushBackgroundTo (element) {
        const content = element.innerHTML
            .split(/\s+/)
            .map(word => `<span>${word}</span>`)
            .join(' ');

        element.innerHTML = content;

        let previousOffset = 0;

        let line = 1;
        const lines = new Map();

        element.querySelectorAll('span').forEach((span, index) => {
            const currentOffset = span.offsetTop;

            if (index === 0 || currentOffset === previousOffset) {
                const lineItems = lines.get(line) || [];
                lines.set(line, lineItems.concat(span));

                previousOffset = currentOffset;
            }

            if (currentOffset > previousOffset) {
                ++line;
                const lineItems = lines.get(line) || [];
                lines.set(line, lineItems.concat(span));
                previousOffset = currentOffset;
            }
        });

        const headlines = [];

        for (const line of lines.values()) {
            const text = line.map(word => word.innerHTML).join(' ');
            headlines.push(text);
        }

        const result = headlines.map(text => {
            /**
             * The <span> inside the <span class="brush-bg"> is used to
             * position the text on top of the background. The back-
             * ground is injected using a ::before pseudo element.
             */
            return `<span class="o-brushbg">
                        <span>${text}</span>
                    </span>`;
        }).join('');

        element.innerHTML = result;
    }

    /**
     * Remove the brush background from selected headlines. Removing it
     * from those currently having a brush background assigned.
     *
     * @returns {this}
     */
    removeBrushBackgroundFromHeadlines () {
        this.headlinesRequiringBrushBackground().forEach(headline => {
            this.removeBrushBackgroundFrom(headline);
        });

        return this;
    }

    /**
     * Removing the brush background for the given `element` is done by
     * retrieving the content of the <span> wrapper which carries the
     * brush background. Then assign only the content to the element.
     *
     * @param {Element} element
     *
     * @returns {this}
     */
    removeBrushBackgroundFrom (element) {
        let content = '';

        element.querySelectorAll('.o-brushbg span').forEach((span) => {
            content = content.concat(span.innerHTML).concat(' ');
        });

        if (content) {
            element.innerHTML = content.trim();
        }

        return this;
    }
}

export default BrushHeadlines;
