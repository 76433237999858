/**
 * Newsticker Class
 *
 * @version 1.0.0
 * @copyright 2032 SEDA.digital GmbH & Co. KG
 */

'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./AntenneConfig').default} AntenneConfig
 * @typedef {import('./CommonMethods').default} CommonMethods
 */
import ClassLogger from 'ClassLogger';
import { ApiClient } from './ApiClient';

export default class Newsticker {
    getClassName () { return 'Newsticker'; }

    /**
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     */
    constructor (commonMethods, navigationHandler) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;

        if (
            window.nativeJsBridge.isWebview ||
            document.documentElement.classList.contains('template--nativeapp') ||
            document.documentElement.classList.contains('template--accountminimal')
        ) {
            this.logger.log('Not initializing because of non-default web template/client.');
            return;
        }

        /** @private */
        this.apiClient = new ApiClient();

        this.renderedNewstickerIds = [];

        this.navigationHandler
            .on('render', () => {
                this.checkForNewstickerIds();
            })
            .on('ready', () => {
                this.init();
            });

        window.newstickerInit = () => this.init();
    }

    checkForNewstickerIds () {
        if (window.antenne &&
            window.antenne.newsticker &&
            window.antenne.newsticker.current &&
            Array.isArray(window.antenne.newsticker.current)) {
            const foundNewIds = window.antenne.newsticker.current.some(id => {
                return this.renderedNewstickerIds.includes(id) === false;
            });

            if (foundNewIds) {
                this.logger.warn('Found new newsticker Ids in HTML, fetching...');
                this.init();
            }
        }
    }

    async init () {
        this.logger.log('Fetching newsticker messages');

        let messages = [];
        try {
            messages = await this.fetchNewsticker();
            this.logger.log('Got messages', messages);
        } catch (error) {
            this.logger.error('Could not fetch newsticker', error);
            return;
        }

        const messagesToRender = [];
        messages.forEach(message => {
            if (new Date(message.publishedon) > Date.now()) {
                // not yet published (unlikely to happen, as API only returns published items)
                return;
            }
            if (new Date(message.expireson) < Date.now()) {
                // expired
                return;
            }
            this.renderedNewstickerIds.push(message.id);
            messagesToRender.push(message);
        });

        this.render(messagesToRender);
    }

    /**
     * @param {array} messages
     */
    render (messages) {
        if (messages.length === 0) {
            return;
        }

        document.documentElement.classList.add('has-newsticker');

        // Remove any previous newsticker
        const previousElement = document.querySelector('.c-newsticker');
        if (previousElement) {
            previousElement.remove();
        }

        let messageNodeTag = 'a';
        let newstickerElement = '<div class="c-newsticker"></div>';
        if (messages.length === 1) {
            newstickerElement = `<a class="c-newsticker" href="${messages[0].url}"></a>`;
            messageNodeTag = 'span';
        }

        newstickerElement = this.commonMethods.markupToElement(newstickerElement);
        document.documentElement.append(newstickerElement);

        let text = '';
        messages.forEach(message => {
            this.logger.log('Rendering message', message);
            // eslint-disable-next-line max-len
            text = `${text} <${messageNodeTag} data-newstickerid="${message.id}" href="${message.url}" class="o-headline--size6">${message.ticker}</${messageNodeTag}>`;

            let expiresIn = new Date(message.expireson).getTime() - Date.now();
            // add a random -15/+15s for preventing DDOS
            expiresIn += Math.round(this.commonMethods.randomIntWithin((Math.min(15, expiresIn + 5) * -1), 15) * 1000);
            // need a maximum here, as too long expireson could immediately run the timeout-function!
            if (expiresIn < 24 * 60 * 60 * 1000) {
                this.logger.log('Will remove message in ', expiresIn);
                // known issue: if newsticker has been rendered before (see `previousElement`),
                // ...we will end up with 2 timeouts for the same message id
                setTimeout(async () => {
                    // Remove expired - and cleanup
                    const elements = document.querySelectorAll(`.c-newsticker [data-newstickerid="${message.id}"]`);
                    elements.forEach(element => {
                        this.logger.log('Removing newsticker messages ' + message.id, element);
                        element.remove();
                    });

                    // Fetch for new messages
                    await this.init();

                    // check if this was the last rendered message
                    if (document.querySelectorAll('.c-newsticker [data-newstickerid]').length === 0) {
                        newstickerElement.remove();
                        document.documentElement.classList.remove('has-newsticker');
                    }
                }, expiresIn);
            }
        });

        // if only 1-2 messages, we duplicate the text
        if (messages.length <= 2) {
            text = `${text} ${text}`;
        }

        newstickerElement.append(this.commonMethods.markupToElement(`<span class="c-newsticker__text">${text}</span>`));

        // calculate duration ("speed")
        // @see https://stackoverflow.com/a/67325362
        const timePerPixel = 11; // milliseconds
        const newstickerTextElement = newstickerElement.querySelector('.c-newsticker__text');
        const distance = newstickerTextElement.offsetWidth + newstickerElement.offsetWidth;
        const animationDuration = timePerPixel * distance;
        newstickerElement.style.setProperty('--animationduration', `${animationDuration}ms`);
    }

    async fetchNewsticker () {
        return await this.apiClient.get('/breaking-news');
    }
}
