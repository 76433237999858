/**
 * CommonMethods.js
 *
 * @version 1
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 *
 * @typedef {import('mitt').Emitter} EventEmitter
 * @typedef {import('../antenne-frontend').Image} Image
 * @typedef {import('../antenne-frontend').UsercentricsService} UsercentricsService
 */

'use strict';

import ClassLogger from 'ClassLogger';
import GeolocationPermissionError from './GeolocationPermissionError';
import Cookies from 'js-cookie';

class CommonMethods {
    getClassName () { return 'CommonMethods'; }

    /**
     * @param {EventEmitter} eventEmitter
     * @param {CommonMethods} commonMethods
     */
    constructor (eventEmitter) {
        /** @protected @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.eventEmitter = eventEmitter;

        this.tcData = null;
        window.antenne = window.antenne || {};
        window.antenne.showCmp = () => this.showCmp();

        const self = this;
        self.__whenCmpIsAvailable = self.__waitForCmpIsAvailable();
        self.__whenExplicitConsentIsGiven = self.__waitForExplicitConsent();

        this.waitForCmpIsAvailable().then(() => {
            const tcStringHandler = (tcData, success) => {
                if (success === true && tcData) {
                    self.logger.log('Refreshing CMP tcData', {
                        eventStatus: tcData.eventStatus,
                        cmpStatus: tcData.cmpStatus,
                    });
                    self.tcData = tcData;
                    // emitted when TCF-Vendor consents change
                    if (tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded') {
                        self.eventEmitter.emit('tcf_consent_change', tcData);
                    }
                }
            };
            __tcfapi('addEventListener', 2, tcStringHandler);
        });

        // 'UC_CMP_EVENT' is a custom "window event" configured at UserCentrics
        window.addEventListener('UC_CMP_EVENT', function (e) {
            self.logger.log('Received UC_CMP_EVENT', e.detail);
            if (e.detail.action === 'onUpdateServices') {
                // emitted when (custom) UserCentrics (non-TCF) vendor consents change
                self.eventEmitter.emit('uc_consent_change', e.detail);
            }
        });
    }

    async waitForCmpIsAvailable () {
        return this.__whenCmpIsAvailable;
    }

    async waitForExplicitConsent () {
        return this.__whenExplicitConsentIsGiven;
    }

    async __waitForCmpIsAvailable ({ timeoutInSeconds } = {}) {
        const promise = new Promise((resolve, reject) => {
            if (window.UC_UI && window.UC_UI.isInitialized()) {
                resolve();
            } else {
                window.addEventListener('UC_UI_INITIALIZED', () => {
                    resolve();
                });
            }
        });

        return timeoutInSeconds > 0
            ? Promise.race([promise, this.timeoutAfterSeconds(timeoutInSeconds)])
            : promise;
    }

    async __waitForExplicitConsent ({ timeoutInSeconds } = {}) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            self.waitForCmpIsAvailable().then(() => {
                if (Object.keys(window.UC_UI_USER_SESSION_DATA || {}).length > 0) {
                    this.logger.log('Assuming explicit consent via native app');
                    resolve();
                }
            }).catch(() => {});
            self.eventEmitter.on('tcf_consent_change', (tcData) => {
                if (tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded') {
                    self.logger.log('Got explicit cmp consent');
                    resolve();
                }
            });
        });

        return timeoutInSeconds > 0
            ? Promise.race([promise, this.timeoutAfterSeconds(timeoutInSeconds)])
            : promise;
    }

    async getTcString () {
        if (this.tcData === null || !this.tcData.tcString) {
            throw new Error('tcString not (yet) set');
        }
        return this.tcData.tcString;
    }

    async showCmp () {
        // native requests geolocation permission during onboard, so we can request immediately
        if (window.nativeJsBridge.isWebview) {
            window.nativeJsBridge.callHandler('cmp.show', {});
            return;
        }
        await this.waitForCmpIsAvailable();
        if (window.UC_UI) {
            window.UC_UI.showSecondLayer();
            return;
        }
        throw new Error('No CMP available');
    }

    /**
     * Get consent status for IAB Vendor
     * @param {int[]} vendorIds Array of TCF IAB Vendor IDs
     * @returns {Promise<array<int, boolean>>} consent status
     */
    async getTcfConsents (vendorIds) {
        const self = this;
        if (!Array.isArray(vendorIds)) {
            throw new TypeError('vendorIds must be an array of integers.');
        }

        return new Promise((resolve, reject) => {
            self.waitForCmpIsAvailable().then(() => {
                const tcData = self.tcData;
                if (typeof tcData !== 'object' || !tcData.vendor) {
                    reject(new Error('Stored tcData from CMP is not available'));
                }
                const results = {};
                vendorIds.forEach(id => {
                    if (!Number.isInteger(id)) {
                        throw new TypeError('vendorId must be an integer.');
                    }
                    if (typeof tcData.vendor.consents[id] !== 'boolean') {
                        tcData.vendor.consents[id] = false;
                    }
                    if (tcData.vendor.consents[id] === false) {
                        self.logger.log('NO consent for IAB vendor ' + id);
                    }
                    results[id] = tcData.vendor.consents[id];
                });
                if (Object.keys(results).length !== vendorIds.length) {
                    this.logger.error('Unexpected numer of consents generated', [
                        vendorIds,
                        results,
                    ]);
                    reject(new Error('Unexpected numer of consents generated'));
                }
                resolve(results);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    /**
     * Get consent status for IAB Vendor
     * @param {string[]} vendorIds Array of Usercentrics Vendor TemplateIds
     * @returns {Promise<UsercentricsService[]>} array of UC service objects incl. consent status
     */
    async getConsents (vendorTemplateIds = []) {
        if (!Array.isArray(vendorTemplateIds)) {
            throw new TypeError('vendorTemplateIds must be an array or undefined');
        }
        await this.waitForCmpIsAvailable();
        let consents = window.UC_UI.getServicesBaseInfo();
        if (vendorTemplateIds.length > 0) {
            consents = consents.filter(service => vendorTemplateIds.includes(service.id));
        }
        return consents;
    }

    /**
     * Determine whether the user gave a consent for the given `templateId`.
     * The template ID is also called service ID in the Usercentrics docs.
     *
     * @param {string} templateId
     *
     * @returns {Promise<boolean>}
     */
    async hasConsentForTemplateId (templateId) {
        return [...await this.getConsents([templateId])].some(service => {
            return service.id === templateId && service.consent.status === true;
        });
    }

    /**
     * Determine whether all consents are accepted.
     *
     * @returns {Boolean}
     */
    allConsentsAccepted () {
        return window.UC_UI
            ? window.UC_UI.areAllConsentsAccepted()
            : false;
    }

    /**
     * Returns a promise that resolves once the DOM is ready
     * this is useful for async loaded scripts
     * @returns Promise
     */
    async isDomReady () {
        return new Promise((resolve, reject) => {
            if (document.readyState === 'interactive' || document.readyState === 'complete') {
                resolve();
                return;
            }

            document.addEventListener('DOMContentLoaded', () => {
                resolve();
            });
        });
    }

    debounce (func, wait = 300, immediate = false) {
        const self = this;
        if (!self.debounceTimers) {
            self.debounceTimers = {};
        }
        return function () {
            const context = this;
            const args = arguments;
            const later = function () {
                self.debounceTimers[func] = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !self.debounceTimers[func];
            if (self.debounceTimers[func]) clearTimeout(self.debounceTimers[func]);
            self.debounceTimers[func] = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    /**
     * Use to delay a function
     *
     * @param {int} ms
     */
    async sleep (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * Returns a promise that rejects after the given `timeoutInSeconds`.
     *
     * @param {int} timeoutInSeconds  the number of seconds before timing out
     *
     * @returns {Promise<void>}
     */
    timeoutAfterSeconds (timeoutInSeconds) {
        return new Promise((_resolve, reject) => {
            const id = setTimeout(() => {
                clearTimeout(id);
                reject(new Error(`timeout after ${timeoutInSeconds} seconds`));
            }, timeoutInSeconds * 1000);
        });
    }

    getCookie (name) {
        return Cookies.get(name) || null;
    }

    /**
     * Attention! In order to delete a cookie, you MUST ensure
     * to set the same path+domain as when the cookie was created
     */
    deleteCookie (name, path = '/', domain = window.location.host) {
        if (Cookies.get(name)) {
            Cookies.remove(name, { path, domain, secure: true, sameSite: 'Lax' });
        }
    }

    /**
     * Test if localStorage is supported and works as expected
     * @returns boolean
     */
    testLocalStorageSupport () {
        if (typeof localStorage !== 'undefined') {
            try {
                localStorage.setItem('feature_test', 'yes');
                if (localStorage.getItem('feature_test') === 'yes') {
                    localStorage.removeItem('feature_test');
                    return true;
                }
            } catch (e) {
                // localStorage is disabled
            }
        }
        return false;
    }

    markupToElement (markup) {
        const template = document.createElement('template');
        template.innerHTML = markup.trim();
        return template.content.firstChild;
    }

    getTemplateVariation () {
        const matches = /template--([\w]+)/g.exec(document.documentElement.classList.toString());
        if (matches && matches[1]) {
            return matches[1];
        }
        throw new Error('Template variation class not found on <html> tag.');
    }

    /**
     *
     * @param {string} iconName
     * @param {string} classes
     * @param {string} attributes
     */
    getSvgIcon (iconName, classes = '', attributes = '') {
        return `
        <svg role="presentation" class="${classes}" ${attributes}>
            <use
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xlink:href="/dist/websites/sprite-${this.getTemplateVariation()}.svg#${iconName}"
                href="/dist/websites/sprite-${this.getTemplateVariation()}.svg#${iconName}"
            ></use>
        </svg>
    `.trim();
    }

    /**
     * Returns the SVG weather icon markup for the given `iconName`.
     *
     * @param {string} iconName
     * @param {string} classes
     * @param {string} attributes
     */
    getWeatherSvgIcon (iconName, classes = '', attributes = '') {
        return `
            <svg role="presentation" class="${classes}" ${attributes}>
                <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/dist/websites/sprite-${this.getTemplateVariation()}-weathericons.svg#${iconName}"
                    href="/dist/websites/sprite-${this.getTemplateVariation()}-weathericons.svg#${iconName}"
                ></use>
            </svg>
        `.trim();
    }

    /**
     * @param {string} classes
     * @param {string} attributes
     */
    getXtraLogo (classes = '', attributes = '') {
        const src = `/dist/websites/logo-xtra-${this.getTemplateVariation()}.svg`;

        return `<img src="${src}" class="${classes}" ${attributes} alt="Xtra" />`.trim();
    }

    /**
     * @param {Image[]} images
     * @param {string} requiredSize
     * @param {string} fileExtension
     */
    findMatchingImage (images, requiredSize, fileExtension = '') {
        /** @type {Image[]} */
        const matches = [];
        // we have loop iterations whereby no image is set. Take care of that.
        if (!Array.isArray(images)) {
            return '';
        }

        for (const image of images) {
            if (image.url.endsWith(fileExtension) && image.size === requiredSize) {
                return image.url;
            } else {
                matches.push(image);
            }
        }

        matches.sort((a, b) => a.size - b.size);
        const requiredWidth = requiredSize.split('x');

        for (const match of matches) {
            const width = match.size.split('x');

            if ((parseInt(width[0]) >= parseInt(requiredWidth[0])) && match.url.endsWith(fileExtension)) {
                return match.url;
            }
        }

        return '';
    }

    /**
     * Returns a geolocation object follwing the browser’s `GeolocationPosition` interface.
     * @param {object} options
     * @returns {Promise<GeolocationPosition>}
     */
    async getGeolocation ({ askForPermission = false, useCache = true, timeout = 10000 }) {
        if (window.nativeJsBridge.isWebview) {
            // native requests geolocation permission during onboard, so we can request immediately
            return await this.requestGeolocationFromNativeApp();
        }

        const permissionState = await this.getGeolocationPermission();

        if (askForPermission === true || permissionState === 'granted') {
            try {
                return await this.requestGeolocationFromBrowser({ timeout });
            } catch (error) {

            }
        }

        const lastPosition = JSON.parse(sessionStorage.getItem('geolocation.lastposition')) || false;
        if (useCache === true && lastPosition && lastPosition.coords) {
            if (lastPosition.timestamp > Date.now() - 60 * 60 * 1000) {
                this.logger.log('Using cached geolocation', lastPosition);
                // Note: In this case we are not returning a GeolocationPosition,
                // but a simple object restored from storage
                return lastPosition;
            } else {
                this.logger.log('Last saved position expired. Ignoring storage', lastPosition);
            }
        }

        this.logger.debug('No geolocation permission granted so far');
        throw new GeolocationPermissionError('No permission granted', permissionState);
    }

    /**
     * @returns {Promise<PermissionState>}
     */
    async getGeolocationPermission () {
        // Testing for older browsers if we have permission ability.
        if (navigator.permissions && navigator.permissions.query) {
            return (await navigator.permissions.query({ name: 'geolocation' })).state;
        } else {
            this.logger.log('Permission API is not available!');
            throw new GeolocationPermissionError('Permission API not supported', 'not-supported');
        }
    }

    /**
     * Request the user’s geolocation from the native smartphone app.
     *
     * @returns {Promise<GeolocationPosition>}
     */
    async requestGeolocationFromNativeApp () {
        this.logger.debug('Requesting geolocation from native bridge');
        return {
            coords: await window.nativeJsBridge.callHandler('geolocation.get', {}),
            timestamp: Date.now(),
        };
    }

    /**
     * Request the user’s geolocation from the browser.
     *
     * @returns {Promise<GeolocationPosition>}
     */
    async requestGeolocationFromBrowser ({
        enableHighAccuracy = true,
        timeout = 10000, // milliseconds
        maximumAge = 60000, // milliseconds
    }) {
        const geolocationOptions = {
            enableHighAccuracy,
            timeout,
            maximumAge,
        };

        this.logger.warn('Requesting geolocation from browser', geolocationOptions);
        return new Promise((resolve, reject) => {
            const successHandler = (position) => {
                this.logger.log('Got geolocation', position);

                let timestamp = position.timestamp;

                if (timestamp < (Date.now() - geolocationOptions.maximumAge)) {
                    // eslint-disable-next-line max-len
                    this.logger.warn('Received geolocation position timestamp that is older than the allowed maximum age. Using current time instead', {
                        now: Date.now(),
                        positionTimestamp: timestamp,
                        maximumAge: geolocationOptions.maximumAge,
                    });

                    timestamp = Date.now();
                }

                sessionStorage.setItem('geolocation.lastposition', JSON.stringify({
                    timestamp,
                    coords: {
                        accuracy: position.coords.accuracy,
                        altitude: position.coords.altitude,
                        altitudeAccuracy: position.coords.altitudeAccuracy,
                        heading: position.coords.heading,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        speed: position.coords.speed,
                    },
                }));
                resolve(position);
            };

            const errorHandler = (error) => {
                if (error instanceof window.GeolocationPositionError &&
                    error.code === window.GeolocationPositionError.PERMISSION_DENIED) {
                    reject(new GeolocationPermissionError(error.message, 'denied'));
                }
                reject(error);
            };

            if (!navigator.geolocation) {
                reject(new Error('Standortfreigabe nicht vom Browser unterstützt.'));
            } else {
                navigator.geolocation.getCurrentPosition(successHandler, errorHandler, geolocationOptions);
            }
        });
    }

    /**
     * Format the given `milliseconds` to a human-readable format in HH:MM:SS.
     *
     * @param {Number} milliseconds
     *
     * @returns {String}
     */
    formatMillisecondsToHMS (milliseconds) {
        let seconds = Math.floor((milliseconds / 1000) % 60);
        let minutes = Math.floor((milliseconds / 1000 / 60) % 60);
        const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

        if (hours > 0) {
            minutes = minutes > 9 ? minutes : `0${minutes}`;
            seconds = seconds > 9 ? seconds : `0${seconds}`;

            return `${hours}h ${minutes}m ${seconds}s`;
        }

        if (minutes > 0) {
            seconds = seconds > 9 ? seconds : `0${seconds}`;

            return `${minutes}m ${seconds}s`;
        }

        return `${seconds}s`;
    }

    /**
     * Returns a random integer between `min` and `max`, including `min` and `max`.
     *
     * @param {Number} min
     * @param {Number} max
     *
     * @returns {Number}
     */
    randomIntWithin (min, max) {
        if (max - min <= 1) {
            return min;
        }

        return Math.floor(
            Math.random() * (max - min + 1) + min,
        );
    }

    /**
     * Decodes base64 to UTF8. For preserving special characters.
     * @see https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
     *
     * @param {string} base64String
     * @returns {string}
     */
    b64DecodeUnicode (base64String) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(base64String).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    async hash (message, algorithm = 'SHA-256') {
        try {
            const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
            const hashBuffer = await crypto.subtle.digest(algorithm || 'SHA-256', msgUint8); // hash the message
            const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
            const hashHex = hashArray
                .map((b) => b.toString(16).padStart(2, '0'))
                .join(''); // convert bytes to hex string
            return hashHex;
        } catch (error) {
            this.logger.error('Failed generating hash', { algorithm });
            throw error;
        }
    }

    async loadScript (url, timeout = 30) {
        const promise = new Promise(function (resolve, reject) {
            const script = document.createElement('script');
            script.onload = resolve;
            script.onerror = () => {
                reject(new Error('Could not load script: ' + url));
            };
            script.src = url;
            script.async = true;
            document.getElementsByTagName('head')[0].appendChild(script);
        });

        return Promise.race([
            this.timeoutAfterSeconds(timeout),
            promise,
        ]);
    }
}

export default CommonMethods;
