'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('mitt').Emitter} EventEmitter
 * @typedef {import('../openapi-generated').Channel} Channel
 * @typedef {import('../openapi-generated').Metadata} Metadata
 * @typedef {import('./ChannelService').default} ChannelService
 * @typedef {import('./MetadataService').default} MetadataService
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';

export default class ChannelCurrentlyPlaying {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'ChannelCurrentlyPlaying';
    }

    /**
     * @param {EventEmitter} eventEmitter
     * @param {NavigationHandler} navigationHandler
     * @param {ChannelService} channelService
     * @param {MetadataService} metadataService
     */
    constructor (eventEmitter, navigationHandler, channelService, metadataService) {
        this.eventEmitter = eventEmitter;
        this.channelService = channelService;
        this.metadataService = metadataService;
        this.navigationHandler = navigationHandler;

        /** @protected @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        if (document.documentElement.classList.contains('template--accountminimal')) {
            return;
        }

        /** @type {Promise<HTMLElement[]>} */
        this.currentlyPlayingTags = new Promise((resolve, reject) => {
            this.navigationHandler
                .on('ready', () => {
                    resolve(this.findChannelCurrentlyPlayingTags());
                });
        });

        this.navigationHandler
            .on('render', async () => {
                this.currentlyPlayingTags = new Promise((resolve, reject) => {
                    this.findChannelCurrentlyPlayingTags().then(tags => resolve(tags));
                });
                this.assignLastPlayingMetadata();
            })
            .on('ready', () => {
                this.listenForMetadata();
                // theoretically we could call this.assignLastPlayingMetadata(); here, but not really needed
            });
    }

    /**
     * Find DOM elements carrying the `data-mountpoint-currently-playing="<mountpoint>"` tag.
     *
     * @returns {Array<{ element: HTMLElement, channelkey: string, mountpoint: string }>}
     */
    async findChannelCurrentlyPlayingTags () {
        const channels = await this.channelService.getChannels();
        const tags = [];
        document.querySelectorAll('[data-currently-playing-channelkey]').forEach(element => {
            if (!element.dataset.currentlyPlayingChannelkey) {
                this.logger.warn('Missing "data-currently-playing-channelkey" attribute found', {
                    element,
                });
            }

            const channelkey = element.dataset.currentlyPlayingChannelkey;

            const channel = channels.find(channel => channel.channelkey === channelkey);
            if (!channel) {
                this.logger.warn('Found invalid "data-currently-playing-channelkey" attribute', {
                    element,
                    channelkey,
                });
                return;
            }

            tags.push({
                element,
                channelkey,
                mountpoint: channel.stream.mountpoint,
            });
        });

        this.logger.log(
            `Found ${tags.length} tag(s) showing currently playing details on channels`,
            { tags },
        );

        return tags;
    }

    /**
     * Get last polled metadata from the metadataService for initial display
     *
     * @returns {this}
     */
    async assignLastPlayingMetadata () {
        const mountpoints = ((await this.currentlyPlayingTags)).map(tag => tag.mountpoint);
        mountpoints.forEach(async mountpoint => {
            const metadata = await this.metadataService.getByMountpoint(mountpoint);
            if (metadata !== undefined) {
                this.assignCurrentlyPlaying(metadata);
            }
        });
    }

    /**
     * Listen for channel metadata on the event bus. Then update the information
     * on the currently visited page by displaying the related song and artist.
     *
     * @returns {this}
     */
    listenForMetadata () {
        this.eventEmitter.on('metadata:now', metadata => {
            this.logger.log('Received metadata to display what’s currently playing for channels', { metadata });

            metadata.forEach(metadataItem => {
                this.assignCurrentlyPlaying(metadataItem);
            });
        });

        return this;
    }

    /**
     * @param {Metadata | undefined} metadata
     *
     * @private
     */
    async assignCurrentlyPlaying (metadata) {
        if (metadata === undefined || !metadata.mountpoint) {
            this.logger.error('Provided metadata has no mountpoint value. Ignoring this one.', { metadata });
            return;
        }

        // Get all tags with this mountpoint
        const tags = (await this.currentlyPlayingTags).filter(tag => {
            return tag.mountpoint === metadata.mountpoint;
        });

        if (tags.length === 0) {
            // this.logger.log('No tags found for mountpoint ' + metadata.mountpoint);
            return;
        }

        this.logger.log('Assigning metadata on what’s currently playing for mountpoint ' + metadata.mountpoint, {
            metadata,
            tags,
        });

        tags.forEach(async tag => {
            if (!this.canUpdateCurrentlyPlaying(metadata)) {
                this.logger.info('Metadata message cannot update tags. Falling back to channel defaults', {
                    metadata,
                });

                try {
                    const channel = await this.channelService.getChannel(tag.channelkey);
                    metadata = { title: channel.title, artist: channel.claim };
                } catch (error) {
                    this.logger.warn('No channel found for mountpoint. Leaving "currently playing" as is', {
                        mountpoint: metadata.mountpoint,
                        metadata,
                    });
                    return;
                }
            }

            const song = tag.element.querySelector('[data-song]');

            if (song) {
                song.textContent = metadata.title;
            }

            const artist = tag.element.querySelector('[data-artist]');

            if (artist) {
                artist.textContent = ` - ${metadata.artist}`;
            }
        });
    }

    /**
     * Determine whether the given `metadata` item can update the DOM.
     *
     * @param {Metadata} metadata
     *
     * @returns {Boolean}
     */
    canUpdateCurrentlyPlaying (metadata) {
        return metadata.title &&
                metadata.artist &&
                String(metadata.class).toLowerCase() === 'music';
    }
}
