'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('../AntenneConfig').default} AntenneConfig
 * @typedef {import('../antenne-frontend').ApiResponsePayload} ApiResponsePayload
 * @typedef {(
 *  resolve: (value: T | PromiseLike<T>) => void,
 *  reject: (reason?: any) => void,
 *  abortController: AbortController
 * ) => void} AbortablePromiseExecutor
 */

import ClassLogger from 'ClassLogger';
import { ApiError, ApiRequestTimeoutError } from './ApiError';
import AbortablePromise from './AbortablePromise';

export class ApiClient {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     *
     * @protected
     */
    getClassName () {
        return 'ApiClient';
    }

    /**
     * Create a new instance.
     */
    constructor () {
        this.baseUrl = this.resolveBaseUrl();
        this.fetchTimeoutInSeconds = 10;
        this.eventEmitter = window.antenneEmitter;

        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
    }

    /**
     * Resolves the base URL.
     *
     * @returns {String}
     *
     * @private
     */
    resolveBaseUrl () {
        const { protocol, host } = window.location;

        return `${protocol}//${host}/api`;
    }

    /**
     * Resolves the final URL by prefixing it with the base URL.
     *
     * @param {String} url
     *
     * @returns {String}
     *
     * @private
     */
    url (url) {
        url = String(url).startsWith('/')
            ? url.slice(1)
            : url;

        return `${this.baseUrl}/${url}`;
    }

    /**
     * Send a GET request to the given `urlPath`.
     *
     * @param {String} urlPath
     * @param {*} options
     */
    get (urlPath, options = {}) {
        return this.request('GET', this.url(urlPath), options);
    }

    /**
     * Send a POST request to the given `urlPath` with the provided `body`.
     *
     * @param {String} urlPath
     * @param {*} body
     */
    post (urlPath, body, options = {}) {
        return this.request('POST', this.url(urlPath), {
            body: JSON.stringify(body),
            ...options,
        });
    }

    /**
     * Send a DELETE request to the given `urlPath`.
     *
     * @param {String} urlPath
     */
    delete (urlPath) {
        return this.request('DELETE', this.url(urlPath));
    }

    /**
     * Sends a request to the given `url` using the `method` and `options`.
     *
     * @param {String} method
     * @param {String} url
     * @param {RequestInit} options
     *
     * @returns {AbortablePromise}
     */
    request (method, url, options = {}) {
        return new AbortablePromise((resolve, reject, abortController) => {
            return this
                .createRequestOptions(method, url, options)
                .then(requestOptions => {
                    return this.sendRequest(url, requestOptions, abortController);
                })
                .then(response => {
                    return this.getResponseData(response);
                })
                .then(data => resolve(data))
                .catch(error => {
                    if (error instanceof DOMException && error.name === 'AbortError') {
                        this.logger.log('Request was aborted. Triggering resolve.');
                        return resolve();
                    }

                    if (error instanceof ApiError && error.hasOverlay) {
                        let content = `
                            <h1>${error.overlay.heading}</h1>
                            <p>${error.overlay.message}</p>
                        `;
                        if (error.overlay.button) {
                            content += `
                                <a href="${error.overlay.button.link}" data-navhandlerlink="ignore-local">
                                    ${error.overlay.button.label}
                                </a>
                            `;
                        }
                        this.eventEmitter.emit('dialog.renderAndShow', {
                            title: 'Oops…',
                            description: 'Es trat ein Fehler auf',
                            content,
                            cssContentClasses: 'c-dialog__content--whitebg c-dialog__content--errordialog',
                        });
                    }

                    reject(error);
                });
        });
    }

    /**
     * Creates and returns the request options for the fetch request.
     *
     * @param {String} method
     * @param {String} url
     * @param {RequestInit} requestOptions
     *
     * @returns {Promise<RequestInit>}
     *
     * @private
     */
    async createRequestOptions (method, url, options) {
        const requestOptions = {
            method,
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                AntenneGroupWebsiteVersion: window.loadedlog.build || 'unknown-build',
                AntenneGroupWebsiteStation: window.antenne.config.station.stationkey || 'unknown',
            },
            ...options,
        };

        /**
         * We decided with Nuuk to append the Antenne Auth-Token to all JavaScript API
         * requests, because the native apps are not allowed to modify any request
         * headers. We’re restricting that handling to some selected URLs.
         */
        if (
            window.nativeJsBridge.isWebview &&
            (
                url.includes('/bookmarks') ||
                url.includes('/usersession') ||
                url.includes('/tokenhunt')
            )
        ) {
            try {
                const authToken = await this.getAbyAuthTokenFromNative();
                this.logger.log('Adding Authorization header to API request');
                requestOptions.headers.Authorization = `Bearer ${authToken}`;
            } catch (error) {
                this.logger.warn('Failed to get ABY-Auth-Token from JS-Bridge', JSON.stringify(error));
            }
        }

        return requestOptions;
    }

    async getAbyAuthTokenFromNative () {
        const authToken = await window.nativeJsBridge.callHandler('antenne.auth.token', {});

        if (typeof authToken !== 'string') {
            throw new TypeError('Received ABY-Auth-Token is not a string value');
        }

        if (authToken === '') {
            throw new TypeError('Received ABY-Auth-Token is an empty string value');
        }

        if (authToken.split('.').length !== 3) {
            throw new TypeError('Received ABY-Auth-Token is an invalid format (expecting signed JWT)');
        }

        return authToken;
    }

    /**
     * Send a request to the given `url`.
     *
     * @param {String} url
     * @param {RequestInit|undefined} requestOptions
     *
     * @returns {*}
     *
     * @private
     */
    async sendRequest (url, requestOptions, abortController) {
        this.logger.log('Sending API request', {
            url,
            requestOptions: {
                ...requestOptions,
                headers: {
                    ...requestOptions.headers,
                    ...(!!requestOptions.headers.Authorization && { Authorization: 'xxx' }),
                },
            },
        });

        try {
            const response = await this.sendRequestWithTimeout(url, requestOptions, abortController);
            this.logger.log('Received API response', response);

            return response;
        } catch (error) {
            this.logger.error('API request failed: ' + JSON.stringify(error), { error });

            if (error instanceof ApiRequestTimeoutError) {
                throw error;
            }

            if (error instanceof DOMException && error.name === 'AbortError') {
                throw error;
            }

            throw ApiError.from({
                error: {
                    code: 'api-request-failed',
                    message: error.message,
                    details: error,
                },
            });
        }
    }

    /**
     * Send off a fetch request to the given `url` using a timeout.
     *
     * @param {String} url
     * @param {RequestInit|undefined} requestOptions
     *
     * @returns {*}
     *
     * @private
     */
    async sendRequestWithTimeout (url, requestOptions, abortController) {
        return await Promise.race([
            fetch(url, {
                ...requestOptions,
                signal: abortController.signal,
            }),
            new Promise((_resolve, reject) => {
                setTimeout(() => {
                    // reject the promise using our API timeout error …
                    reject(
                        new ApiRequestTimeoutError(`API request timed out after ${this.fetchTimeoutInSeconds} seconds`),
                    );

                    // … then send the abort signal because we would receive an "AbortError" otherwise
                    abortController.abort();
                }, this.fetchTimeoutInSeconds * 1000);
            }),
        ]);
    }

    /**
     * Parse the given API `response` payload from JSON and return the contained data.
     *
     * @param {Response} response
     *
     * @private
     */
    async getResponseData (response) {
        /** @type {ApiResponsePayload} */
        let payload;

        try {
            payload = await response.json();
        } catch (error) {
            throw ApiError.from({
                error: {
                    code: 'unexpected-response-payload',
                    message: 'Invalid API response payload. JSON payload expected.',
                    details: error,
                },
            }, response);
        }

        if (payload.success) {
            return payload.data;
        }

        throw ApiError.from(payload, response);
    }
}
