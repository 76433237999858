'use strict';

import ClassLogger from 'ClassLogger';

export default class VolumeControl {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'VolumeControl';
    }

    /**
     * @param {HTMLAudioElement} audioPlayer
     */
    constructor (audioPlayer) {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.audioPlayer = audioPlayer;
        this.volumeButton = document.querySelector('[data-abywebplayer-volume]');
        this.volumeRangeSlider = document.querySelector('[data-abywebplayer-volume-range-slider]');
        this.volumeIconOff = document.querySelector('.c-player__icon--volumeoff');
        this.volumeIconLow = document.querySelector('.c-player__icon--volumedown');
        this.volumeIconLoud = document.querySelector('.c-player__icon--volumeup');

        this.localStorageVolumeLevelKey = 'aby-webplayer-volume';

        this.initVolumeControl();
    }

    /**
     * @returns {this}
     *
     * @private
     */
    initVolumeControl () {
        this.logger.log('Initializing volume control (range slider)');

        return this
            .initMuteButton()
            .initVolumeSlider()
            .initVolumeIcons();
    }

    /**
     * @returns {this}
     *
     * @private
     */
    initMuteButton () {
        if (!this.volumeButton) {
            this.logger.log('Cannot find mute button in volume controls. Skipping mute button initialization.');

            return this;
        }

        this.volumeButton.disabled = false;

        this.volumeButton.addEventListener('click', () => {
            this.toggleMuted();
        });

        return this;
    }

    /**
     * @returns {this}
     *
     * @private
     */
    initVolumeSlider () {
        if (!this.volumeRangeSlider) {
            this.logger.log('Cannot find volume range slider. Skipping volume control initialization.');

            return this;
        }

        this.volumeRangeSlider.disabled = false;

        this.volumeRangeSlider.addEventListener('input', event => {
            this
                .adjustVolumeRangeSlider(event)
                .adjustVolumeIcons();
        });

        return this;
    }

    /**
     * Save the given volume `level` to local storage.
     *
     * @param {CustomEvent|Event} event
     *
     * @returns {this}
     */
    adjustVolumeRangeSlider (event) {
        /**
         * When restoring finding a volume level from a recent visit we’re dispatching
         * a `CustomEvent`. Then the `event.detail.value` contains the recent volume.
         * Otherwise we use the volume level defined in the `event.target.value`.
         */
        if (event.detail && event.detail.value != null) {
            event.target.value = event.detail.value;
        }

        const value = event.target.value;
        this.assignAndSaveVolume(value);

        const min = event.target.min;
        const max = event.target.max;
        event.target.style.backgroundSize = `${(value - min) * 100 / (max - min)}% 100%`;

        return this;
    }

    /**
     * Save the given volume `level` to local storage.
     *
     * @param {Number} level 0-100
     *
     * @returns {this}
     */
    assignAndSaveVolume (level) {
        this.audioPlayer.volume = level / 100;
        localStorage.setItem(this.localStorageVolumeLevelKey, String(level));

        return this;
    }

    /**
     * Dispatch an initial `input` event to adjust the player’s volume volume control.
     *
     * @returns {this}
     *
     * @private
     */
    initVolumeIcons () {
        if (!this.volumeRangeSlider) {
            return this;
        }

        this.volumeRangeSlider.dispatchEvent(new CustomEvent('input', {
            detail: {
                value: this.restoreVolume(),
            },
        }));

        return this;
    }

    /**
     * Restore the previously saved volume level.
     *
     * @returns {String}
     */
    restoreVolume () {
        return localStorage.getItem(this.localStorageVolumeLevelKey);
    }

    /**
     * @returns {this}
     */
    adjustVolumeIcons () {
        const volume = this.isMuted()
            ? 0
            : this.audioPlayer.volume * 100;

        switch (true) {
            case volume > 50:
                this.volumeIconLoud.classList.remove('u-hide');
                this.volumeIconLow.classList.add('u-hide');
                this.volumeIconOff.classList.add('u-hide');
                break;

            case volume > 0 && volume <= 50:
                this.volumeIconLoud.classList.add('u-hide');
                this.volumeIconLow.classList.remove('u-hide');
                this.volumeIconOff.classList.add('u-hide');
                break;

            default: // muted
                this.volumeIconLoud.classList.add('u-hide');
                this.volumeIconLow.classList.add('u-hide');
                this.volumeIconOff.classList.remove('u-hide');
                break;
        }

        return this;
    }

    /**
     * Determine whether the audio player is muted.
     *
     * @returns {Boolean}
     */
    isMuted () {
        return this.audioPlayer.muted;
    }

    /**
     * Toggle mute on the audio player.
     */
    toggleMuted () {
        this.isMuted()
            ? this.unMute()
            : this.mute();

        return this;
    }

    /**
     * @returns {this}
     */
    mute () {
        this.audioPlayer.muted = true;
        this.adjustVolumeIcons();

        return this;
    }

    /**
     * @returns {this}
     */
    unMute () {
        this.audioPlayer.muted = false;
        this.adjustVolumeIcons();

        return this;
    }
}
