'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} commonMethods
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';
import Tabs from './Tabs';
class Tabnav {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'Tabnav';
    }

    /**
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     */
    constructor (commonMethods, navigationHandler) {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());

        window.addEventListener('resize', () => {
            this.commonMethods.debounce(() => {
                this.init(true);
            }, 100)();
        });
    }

    /**
     * Initialize the tabnav.
     *
     * @returns {this}
     */
    init (isResize = false) {
        const tabnavs = document.querySelectorAll('.c-tabnav');

        if (tabnavs.length === 0) {
            return;
        }

        tabnavs.forEach((tabnav) => {
            const controls = tabnav.querySelectorAll('.c-tabnav__controls');

            // Check if we have button controls. If no controls than we don't need to do anything.
            if (controls.length > 0) {
                const mediaQuery = window.matchMedia('(min-width: 600px)');

                // we only want the arrows on medium and up screens
                if (mediaQuery.matches) {
                    let tablist = tabnav.querySelector('.c-tabnav__list');

                    if (isResize) {
                        // Cloning the item and appending it removes all eventListeners added
                        this.resetNav(tabnav);
                        const navtrack = tabnav.querySelector('.c-tabnav__track');
                        const clone = navtrack.cloneNode(true);
                        tabnav.innerHTML = '';
                        tabnav.appendChild(clone);

                        if (tabnav.closest('[data-tabcomponent]')) {
                            // Need to init tabs feature if it exists
                            new Tabs(this.navigationHandler).init();
                        }

                        tablist = tabnav.querySelector('.c-tabnav__list');
                    }

                    const buttonPrev = tabnav.querySelector('.c-tabnav__controls--prev');
                    const buttonNext = tabnav.querySelector('.c-tabnav__controls--next');

                    this.addArrowIdx(tablist);
                    this.arrowNavCheck(tabnav, tablist, buttonPrev, buttonNext);

                    tablist.addEventListener('scroll', (e) => {
                        this.commonMethods.debounce(() => {
                            this.handleArrows(tablist, buttonPrev, buttonNext);
                        }, 100)();
                    });
                } else {
                    // For the case that a nav previously was active
                    this.resetNav(tabnav);
                }

                // Check if our active nav item is visible within viewport
                if (!isResize) {
                    this.checkNavItemIsVisible(tabnav);
                }
            }
        });
    }

    /**
     * Checks our width of our items vs its container.
     *
     * @returns {boolean}
     */
    isContentOverflowing (sWidth, cWidth) {
        if (sWidth > cWidth) {
            return true;
        }

        return false;
    }

    /**
     * Resets the nav classes for the component
     * @param {Node} tabnav
     */
    resetNav (tabnav) {
        tabnav.classList.remove('c-tabnav--has-arrows');
        const buttons = tabnav.querySelectorAll('.c-tabnav__controls');
        buttons.forEach((button) => {
            button.classList.remove('is-disabled');
        });
    }

    /**
     * Uses native browser feature to bring our active nav item into view
     * @param {Node} tabnav
     */
    checkNavItemIsVisible (tabnav) {
        const activeNavItem = tabnav.querySelector('.c-tabnav__item.is-active');
        const navtrack = tabnav.querySelector('.c-tabnav__track');
        const navlist = tabnav.querySelector('.c-tabnav__list');

        if (activeNavItem) {
            if (!this.isItemVisibleInContainer(navtrack, activeNavItem)) {
                navlist.scrollLeft = activeNavItem.offsetLeft;
            }
        }
    }

    /**
     *  Checks if our nav item is visible within the container
     * @param {Noe} container
     * @param {Node} item
     * @returns boolean
     */
    isItemVisibleInContainer (container, item) {
        const itemRect = item.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        return itemRect.right < containerRect.right;
    }

    /**
     * Checks our width of our items vs its container.
     * Decides if we should add arrow nav functionality.
     *
     * @returns {this}
     */
    arrowNavCheck (tabnav, tablist, buttonPrev, buttonNext) {
        if (this.isContentOverflowing(tablist.scrollWidth, tablist.clientWidth)) {
            // If true, add our nav buttons
            tabnav.classList.add('c-tabnav--has-arrows');
            buttonPrev.classList.add('is-disabled');
            buttonNext.classList.remove('is-disabled');
            this.assignArrowNav(tabnav, tablist);
        } else {
            // Hide our navigation elements
            this.resetNav(tabnav);
        }
    }

    /**
     * hides/shows arrows depending on tablist scroll position
     *
     * @returns {this}
     */
    handleArrows (tablist, buttonPrev, buttonNext) {
        if (tablist.scrollLeft <= 5) { // Needed to add a tolerance of 5 pixels to account for extra spacing
            buttonPrev.classList.add('is-disabled');
        } else {
            buttonPrev.classList.remove('is-disabled');
        }

        /**
         * We need to always round up (ceil) the value of scrollLeft because in some use cases
         * the value had a decimal that ended up less than the width of the sliderTrack.
         */
        if (tablist.offsetWidth + Math.ceil(tablist.scrollLeft) + 1 >= tablist.scrollWidth) {
            buttonNext.classList.add('is-disabled');
        } else {
            buttonNext.classList.remove('is-disabled');
        }
    }

    /**
     * Assigns idx to each tab item and assigns the first item with
     * a selected class that is used to monitor the tab navigation
     * @param {Node} tablist
     */
    addArrowIdx (tablist) {
        const tabItems = tablist.querySelectorAll('.c-tabnav__item');
        tabItems.forEach((tabNode, idx) => {
            if (idx === 0) {
                tabNode.classList.add('is-selected');
            }
            tabNode.dataset.tabidx = tabNode.dataset.tabidx || (idx + 1);
        });
    }

    goToSlide (sliderTrack, slideIdx) {
        const slideToShow = sliderTrack.querySelector(`[data-tabidx="${slideIdx}"]`);
        const slideItems = sliderTrack.querySelectorAll('.c-tabnav__item');
        slideItems.forEach((item) => {
            item.classList.remove('is-selected');
        });

        slideToShow.classList.add('is-selected');
        if (slideToShow) {
            sliderTrack.scrollLeft = slideToShow.offsetLeft;
        } else {
            this.logger.warn('Slide to show not found', { slideIdx, sliderTrack });
        }
    }

    goToNext (sliderTrack) {
        const currentSlide = sliderTrack.querySelector('.c-tabnav__item.is-selected');
        if (!currentSlide) {
            this.logger.warn('No active slide found', { sliderTrack });
            return;
        }
        const oldIdx = parseInt(currentSlide.dataset.tabidx);
        if (!isNaN(oldIdx)) {
            let newIdx = oldIdx + 1;
            const slideItemCount = sliderTrack.querySelectorAll('.c-tabnav__item').length;
            if (newIdx >= slideItemCount) {
                newIdx = oldIdx;
            }
            this.goToSlide(sliderTrack, newIdx);
        }
    }

    goToPrev (sliderTrack) {
        const currentSlide = sliderTrack.querySelector('.c-tabnav__item.is-selected');
        if (!currentSlide) {
            this.logger.warn('No active slide found', { sliderTrack });
            return;
        }
        const oldIdx = parseInt(currentSlide.dataset.tabidx);
        if (!isNaN(oldIdx)) {
            let newIdx = oldIdx - 1;
            if (newIdx <= 0) {
                newIdx = oldIdx;
            }
            this.goToSlide(sliderTrack, newIdx);
        }
    }

    assignArrowNav (slider, sliderTrack) {
        const controlPrev = slider.querySelector('.c-tabnav__controls--prev');
        const controlNext = slider.querySelector('.c-tabnav__controls--next');

        controlPrev.addEventListener('click', () => { this.goToPrev(sliderTrack); });
        controlNext.addEventListener('click', () => { this.goToNext(sliderTrack); });
    }
}

export default Tabnav;
