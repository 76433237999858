/**
 * LoadMoreCards
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 */

'use strict';

import ClassLogger from 'ClassLogger';

class LoadMoreCards {
    getClassName () { return 'LoadMoreCards'; }
    constructor (navigationHandler) {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.navigationHandler = navigationHandler;
        self.cardsPerClick = window.matchMedia('(min-width: 1019px)').matches ? 6 : 3;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    init () {
        const self = this;
        const gridsWithLoadMore = document.querySelectorAll('.l-cardgrid.has-loadmore');
        self.logger.log('Init', { gridsWithLoadMore });

        gridsWithLoadMore.forEach(grid => {
            // Load more via button click
            const button = grid.querySelector('button[data-cardgrid]');
            button.addEventListener('click', e => {
                e.preventDefault();
                const gridId = button.dataset.cardgrid;
                self.showMore(gridId, true);
            });

            // Load more automatically via scrolling
            self.oberserveGrid(grid);
        });
    }

    oberserveGrid (grid) {
        const self = this;
        const elementToObserve = self.getLastVisibleCard(grid);
        // const elementToObserve = grid.querySelector('.l-cardgrid__loadmorebutton');

        if (!elementToObserve) {
            self.logger.warn('No element found to observe', { grid });
            return;
        }

        if (!self.observer) {
            // Initialize Observer
            self.observer = new IntersectionObserver((entries, observer) => {
                self.handleIntersect(entries, observer);
            }, {
                root: null,
                rootMargin: '-50px',
            });
        }

        self.logger.log('Starting to observe', elementToObserve);
        self.observer.observe(elementToObserve);
    }

    getLastVisibleCard (grid) {
        const visibleCards = grid.parentNode.querySelectorAll('.l-cardgrid__item:not(.l-cardgrid__item--hidden)');
        return visibleCards[visibleCards.length - 1];
    }

    handleIntersect (entries, observer) {
        const self = this;
        self.logger.log('handling intersect', observer);
        entries.forEach(async (entry) => {
            self.logger.log('intersect', entry);
            if (entry.isIntersecting) {
                observer.unobserve(entry.target);
                const grid = entry.target.closest('.l-cardgrid[data-cardgrid]');
                const gridId = grid.dataset.cardgrid;
                await self.showMore(gridId, false);
                if (self.isGridASliderForCurrentBreakpoint(grid) === true) {
                    // for sliders we re-start observing the (newly added) last card
                    self.oberserveGrid(grid);
                }
            }
        });
    }

    async loadMore (gridId) {
        const self = this;
        const hiddenChilds = document.querySelectorAll(`[data-cardgrid="${gridId}"] .l-cardgrid__item--hidden`);
        const hiddenChildsArray = [].slice.call(hiddenChilds);

        if (hiddenChildsArray.length === 0) {
            throw new Error('No items found');
        }

        const cardsToShow = hiddenChildsArray.slice(0, self.cardsPerClick);
        return {
            cards: cardsToShow,
            remaining: hiddenChildsArray.length,
        };
    }

    async showMore (gridId, scrollTo = false) {
        const self = this;
        self.logger.log('Showing more cards', { gridId, scrollTo });
        let fetchedItems;
        try {
            fetchedItems = await self.loadMore(gridId);
        } catch (error) {
            self.logger.error(error);
            return;
        }

        const { cards: cardsToShow, remaining: remainingCardsCount } = fetchedItems;

        const firstNewCard = cardsToShow[0];
        const sliderTrack = firstNewCard.parentNode;

        if (scrollTo === true) {
            sliderTrack.classList.add('mousedown');
        }

        let lastCard = null;
        cardsToShow.forEach(card => {
            lastCard = card;
            if (scrollTo === true) {
                card.classList.add('l-cardgrid__item--fadein');
            }
            card.classList.remove('l-cardgrid__item--hidden');
        });

        if (scrollTo === true) {
            self.scrollIntoView(firstNewCard).then(() => {
                sliderTrack.classList.remove('mousedown');
                // firstNewCard.focus();
            });
        }

        if (remainingCardsCount <= self.cardsPerClick) {
            lastCard.closest('.l-cardgrid[data-cardgrid]').classList.remove('has-loadmore');
        }
    }

    isGridASliderForCurrentBreakpoint (grid) {
        return this.isSmallBreakpoint() && this.isSliderForSmallBreakpoint(grid);
    }

    isSmallBreakpoint () {
        return document.documentElement.clientWidth <= 600;
    }

    isSliderForSmallBreakpoint (grid) {
        return grid.classList.contains('l-cardgrid--largeslider') ||
            grid.classList.contains('l-cardgrid--smallslider');
    }

    async scrollIntoView (element) {
        const self = this;
        return new Promise((resolve, reject) => {
            const grid = element.closest('.l-cardgrid');

            if (self.isGridASliderForCurrentBreakpoint(grid)) {
                // do nothing for small viewport sliders
                resolve();
            } else {
                element.addEventListener('transitionend', () => {
                    let positionOffset = element.offsetHeight + 16; // 16 = margin
                    const boundingRect = element.getBoundingClientRect();
                    if (positionOffset > boundingRect.top) {
                        positionOffset = boundingRect.top - 25;
                    }
                    window.scroll({
                        top: window.scrollY + positionOffset,
                        behavior: 'smooth',
                    });
                    resolve();
                }, {
                    once: true,
                });
            }
        });
    }

    /*
    scrollLeft (element, position) {
        return new Promise(resolve => {
            const scrollListener = e => {
                if (typeof e === 'undefined') {
                    return;
                }
                const target = e.currentTarget;
                if (target.scrollLeft === position) {
                    target.removeEventListener('scroll', scrollListener);
                    resolve();
                }
            };
            element.addEventListener('scroll', scrollListener);
            element.scrollLeft = position;
        });
    }
    */
}

export default LoadMoreCards;
