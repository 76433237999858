'use strict';

import ClassLogger from 'ClassLogger';

class Slider {
    getClassName () { return 'Slider'; }
    constructor (commonMethods, navigationHandler) {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.commonMethods = commonMethods;

        self.navigationHandler = navigationHandler;

        this.navigationHandler
            .on('ready', () => this.handleSlidersInWrapper(document))
            .on('render', () => this.handleSlidersInWrapper(document));
    }

    handleSlidersInWrapper (wrapper) {
        const sliders = wrapper.querySelectorAll('.l-cardgrid:not(.l-cardgrid--noslider), .c-slider');
        if (sliders.length > 0) {
            sliders.forEach((slider) => {
                this.handleSlider(slider);
            });
        }
    }

    isSmallBreakpoint () {
        return window.matchMedia('(max-width: 600px)');
    }

    handleSlider (slider) {
        const self = this;

        if (slider.classList.contains('is-initialized')) {
            return;
        }

        const sliderTrack = slider.querySelector('.l-cardgrid__items, .c-slider__track');
        if (!sliderTrack) {
            self.logger.log('No slider track found');
            return;
        }

        sliderTrack.dataset.currentslide = 1;

        slider.classList.add('is-initialized');

        /**
         * @var sliderType
         * Only two types exist at the moment: card and the regular component
         * The type determines if and when we want the draghandler to be active
         */
        let sliderType = 'component';
        /**
         * @var itemClass
         * Each type of slider has a different markup structure and uses different
         * classnames for its slider items.
         */
        let itemClass = '.c-slider__item';

        if (slider.classList.contains('l-cardgrid')) {
            sliderType = 'card';
            itemClass = '.l-cardgrid__item';
        }

        sliderTrack.dataset.itemclass = itemClass;
        const sliderItems = sliderTrack.querySelectorAll(itemClass);
        sliderTrack.dataset.total = sliderItems.length;

        // Add slideidx attributes if they do not yet exist
        sliderItems.forEach((slideNode, idx) => {
            slideNode.dataset.slideidx = slideNode.dataset.slideidx || (idx + 1);
        });

        // get sliderOptions Json from data-attribute
        const sliderOptions = slider.dataset.slideroptions;
        let arrowNav = false;

        if (sliderOptions) {
            const sliderOptionsObject = JSON.parse(sliderOptions);
            arrowNav = sliderOptionsObject.arrowNav ? sliderOptionsObject.arrowNav : false;
            const dots = sliderOptionsObject.dots ? sliderOptionsObject.dots : false;
            const autoplay = sliderOptionsObject.autoplay ? sliderOptionsObject.autoplay : false;
            const counter = sliderOptionsObject.counter ? sliderOptionsObject.counter : false;
            const autoplayDuration = sliderOptionsObject.autoplayDuration ? sliderOptionsObject.autoplayDuration : 3000;

            // add arrows/dots/autoplay only if more than 1 slide
            if (sliderItems.length > 1) {
                // when navigating (arrows/dots) we want to reset the interval
                let resetAutoplay = () => {};
                if (autoplay) {
                    // When hovering we want to pause autoplay
                    let isHovered = false;
                    let interval = null;

                    // when navigating (arrows/dots) we want to reset the interval
                    resetAutoplay = () => {
                        clearInterval(interval);
                        interval = setInterval(() => {
                            if (isHovered === true) {
                                // wait until mouseout while hovered
                                clearInterval(interval);
                                slider.addEventListener('mouseout', () => {
                                    self.goToNext(sliderTrack);
                                }, { once: true });
                                return;
                            }
                            self.goToNext(sliderTrack);
                        }, autoplayDuration);
                    };
                    resetAutoplay();

                    slider.addEventListener('mouseover', () => {
                        isHovered = true;
                    });

                    slider.addEventListener('mouseout', () => {
                        isHovered = false;
                    });
                }

                if (arrowNav) {
                    self.arrowNav(slider, sliderTrack, resetAutoplay);
                }

                if (dots) {
                    self.initializeDots(slider, sliderTrack, resetAutoplay);
                }

                if (counter) {
                    self.initializeCounter(slider, sliderTrack);
                }
            }
        }

        // initial disable state
        this.checkSlidePosition(slider, sliderTrack);
        this.handleChangedIdx(slider, sliderTrack, 1);

        // on scroll updates
        sliderTrack.addEventListener('scroll', () => {
            this.commonMethods.debounce(() => {
                this.checkSlidePosition(slider, sliderTrack);
            }, 25)();
        });

        /**
         * Start draghandler if:
         * a) We have a card type and we have a small screen
         * b) We have a component type, regardless of screen size.
         */
        if (self.isSmallBreakpoint() && sliderType === 'card') {
            self.dragHandler(sliderTrack, itemClass);
        } else if (sliderType === 'component') {
            self.dragHandler(sliderTrack, itemClass);
        }

        const observer = new IntersectionObserver((entries) => {
            self.handleSlideIntersect(slider, sliderTrack, entries);
        }, {
            root: slider,
            rootMargin: '0px',
            threshold: 0.15,
        });

        sliderItems.forEach((slide) => {
            observer.observe(slide);
        });
    }

    initializeDots (slider, sliderTrack, resetAutoplay) {
        const self = this;
        const dotWrapper = slider.querySelector('.c-slider__dots');

        if (!dotWrapper) {
            this.logger.warn('No slider dots found', { sliderTrack });
            return;
        }

        // clear out wrapper, just in case Turbolink stuff has ruined it. It was a thing!
        dotWrapper.innerHTML = '';

        const sliderItems = sliderTrack.querySelectorAll(sliderTrack.dataset.itemclass);

        if (sliderItems.length > 1 && dotWrapper !== null) {
            sliderItems.forEach((slide, index) => {
                index = index + 1;
                const dotNode = self.commonMethods.markupToElement(`
                    <li class="c-slider__dot" data-dotindex="${index}">
                        <button data-navhandlerlink="ignore-local" data-slidergoto="${index}">
                            <span class="u-sr-only">zu Slide ${index}</span>
                        </button>
                    </li>
                `);
                dotNode.querySelector('button').addEventListener('click', (e) => {
                    const targetSlideIdx = e.target.dataset.slidergoto;
                    self.goToSlide(sliderTrack, targetSlideIdx);
                    resetAutoplay();
                });
                dotWrapper.append(dotNode);
            });
        }
    }

    initializeCounter (slider, sliderTrack) {
        const self = this;

        const counterElement = self.commonMethods.markupToElement(`
            <div class="c-slider__counter u-text-center">
                <span class="c-slider__count-item" data-currentcounternumber>1</span>
                &nbsp;/&nbsp;
                <span class="c-slider__count-total">${sliderTrack.dataset.total}</span>
            </div>
        `);
        slider.append(counterElement);

        // Relocate buttons into counter area
        const controlPrev = slider.querySelector('.c-slider__control--prev');
        const controlNext = slider.querySelector('.c-slider__control--next');
        if (controlPrev) {
            counterElement.prepend(controlPrev);
        }
        if (controlNext) {
            counterElement.append(controlNext);
        }
    }

    handleSlideIntersect (slider, sliderTrack, entries) {
        const intersectingTargets = [];

        entries.forEach((entry) => {
            if (entry.isIntersecting === true) {
                intersectingTargets.push(entry.target);
            } else {
                entry.target.classList.remove('is-active');
            }
        });

        intersectingTargets.forEach(entryTarget => {
            entryTarget.classList.add('is-active');
        });

        this.checkSlidePosition(slider, sliderTrack);
    }

    goToSlide (sliderTrack, slideIdx) {
        const self = this;
        const slideToShow = sliderTrack.querySelector(`[data-slideidx="${slideIdx}"]`);
        if (slideToShow) {
            sliderTrack.scrollLeft = slideToShow.offsetLeft - parseInt(getComputedStyle(sliderTrack).paddingRight);
        } else {
            self.logger.warn('Slide to show not found', { slideIdx, sliderTrack });
        }
    }

    goToNext (sliderTrack) {
        const self = this;
        const oldIdx = parseInt(sliderTrack.dataset.currentslide);
        if (!isNaN(oldIdx)) {
            let newIdx = oldIdx + 1;
            const slideItemCount = sliderTrack.querySelectorAll(sliderTrack.dataset.itemclass).length;
            if (newIdx > slideItemCount) {
                newIdx = 1;
            }
            self.goToSlide(sliderTrack, newIdx);
        }
    }

    goToPrev (sliderTrack) {
        const self = this;
        const oldIdx = parseInt(sliderTrack.dataset.currentslide);
        if (!isNaN(oldIdx)) {
            let newIdx = oldIdx - 1;

            if (newIdx <= 0) {
                newIdx = sliderTrack.querySelectorAll(sliderTrack.dataset.itemclass).length;
            } else {
                const activeItems = sliderTrack.querySelectorAll(sliderTrack.dataset.itemclass + '.is-active');
                if (activeItems.length > 0) {
                    newIdx = parseInt(activeItems[0].dataset.slideidx) - 1;
                }
            }

            self.goToSlide(sliderTrack, newIdx);
        }
    }

    arrowNav (slider, sliderTrack, resetAutoplay) {
        const self = this;
        const controlPrev = this.commonMethods.markupToElement(`
            <button class="c-slider__control c-slider__control--prev">
                ${this.commonMethods.getSvgIcon('chevron_left')}
            </button>
        `);
        const controlNext = this.commonMethods.markupToElement(`
            <button class="c-slider__control c-slider__control--next">
                ${this.commonMethods.getSvgIcon('chevron_right')}
            </button>
        `);

        slider.append(controlPrev);
        slider.append(controlNext);

        controlPrev.addEventListener('click', () => { self.goToPrev(sliderTrack); resetAutoplay(); });
        controlNext.addEventListener('click', () => { self.goToNext(sliderTrack); resetAutoplay(); });
    }

    dragHandler (sliderTrack, itemClass) {
        let isDown = false;
        let isMoving = false;
        let startX;
        let scrollLeft = 0;
        const mouseUpHandler = (e) => {
            e.preventDefault();
            isDown = false;
            sliderTrack.classList.remove('mousedown');
        };

        sliderTrack.addEventListener('mousedown', (e) => {
            e.preventDefault();
            isDown = true;
            // resets isMoving so clickevent can be triggered again.
            isMoving = false;
            sliderTrack.classList.add('mousedown');
            startX = e.pageX - sliderTrack.offsetLeft;
            scrollLeft = sliderTrack.scrollLeft;
        });

        sliderTrack.addEventListener('mouseleave', mouseUpHandler);

        sliderTrack.addEventListener('mouseup', mouseUpHandler);

        sliderTrack.addEventListener('mousemove', (e) => {
            // TODO add comments here to describe what the purpose is
            if (!isDown) return;
            isMoving = true;
            e.preventDefault();
            const x = e.pageX - sliderTrack.offsetLeft;
            const walk = (x - startX) * 1.5;
            sliderTrack.scrollLeft = scrollLeft - walk;
        });

        sliderTrack.addEventListener('click', e => {
            // TODO add comments here to describe what the purpose is
            if (isMoving) {
                e.preventDefault();
                return false;
            }
            const item = e.target.closest(itemClass);
            if (item) {
                const rect = item.getBoundingClientRect();
                if (
                    // left
                    rect.left + (rect.width / 2) > 0 &&
                    // right
                    rect.left + (rect.width / 2) < (window.innerWidth || document.documentElement.clientWidth)
                ) {
                    // 50% or more visible
                } else {
                    // less than 50% visible
                    e.preventDefault();
                    if (rect.x < 0) {
                        sliderTrack.scrollLeft -= item.offsetWidth;
                    } else {
                        sliderTrack.scrollLeft += item.offsetWidth;
                    }
                    return false;
                }
            }
        });
    }

    // show/hide gradients at the sides
    checkSlidePosition (slider, sliderTrack) {
        const hasNextSlide = this.hasNextSlide(sliderTrack);
        const hasPrevious = sliderTrack.scrollLeft > 0;

        // Those classes are purely used for the left/right GRADIENTS to dissapear asap
        if (hasPrevious === false) {
            slider.classList.add('has-no-prev');
        } else {
            slider.classList.remove('has-no-prev');
        }

        if (hasNextSlide === false) {
            slider.classList.add('has-no-next');
        } else {
            slider.classList.remove('has-no-next');
        }

        // Check if IDX changed
        let newActiveSlideIdx = 1;
        const activeItems = sliderTrack.querySelectorAll(sliderTrack.dataset.itemclass + '.is-active');
        if (activeItems.length > 0) {
            newActiveSlideIdx = parseInt(activeItems[0].dataset.slideidx);
            if (hasNextSlide === false) {
                // if we have NO next slide, we must show the last idx as count
                newActiveSlideIdx = parseInt(activeItems[activeItems.length - 1].dataset.slideidx);
            }
        }

        // if IDX changed...
        if (parseInt(sliderTrack.dataset.currentslide) !== newActiveSlideIdx) {
            // handle counter, arrows, dots
            this.handleChangedIdx(slider, sliderTrack, newActiveSlideIdx);
        }
    }

    handleChangedIdx (slider, sliderTrack, newActiveSlideIdx) {
        sliderTrack.dataset.currentslide = newActiveSlideIdx;

        // Update disabled state of control next/prev buttons
        const controlPrev = slider.querySelector('.c-slider__control--prev');
        const controlNext = slider.querySelector('.c-slider__control--next');
        if (controlPrev && controlNext) {
            if (newActiveSlideIdx === parseInt(sliderTrack.dataset.total)) {
                controlNext.classList.add('is-disabled');
            } else {
                controlNext.classList.remove('is-disabled');
            }

            if (newActiveSlideIdx === 1 || slider.classList.contains('has-no-prev')) {
                controlPrev.classList.add('is-disabled');
            } else {
                controlPrev.classList.remove('is-disabled');
            }
        }

        // Handle Counter if it exists
        const counter = slider.querySelector('.c-slider__counter [data-currentcounternumber]');
        if (counter) {
            counter.innerText = newActiveSlideIdx;
        }

        // Update dot navigation
        const dotWrapper = slider.querySelector('.c-slider__dots');
        if (dotWrapper) {
            // Handline active states of dot navigation
            const dots = dotWrapper.querySelectorAll('.c-slider__dot.is-active');
            dots.forEach(item => {
                item.classList.remove('is-active');
            });

            const item = dotWrapper.querySelector(`.c-slider__dot[data-dotindex="${newActiveSlideIdx}"]`);
            if (item) {
                item.classList.add('is-active');
            }
        }
    }

    hasNextSlide (sliderTrack) {
        /**
         * We need to always round up (ceil) the value of scrollLeft because in some use cases
         * the value had a decimal that ended up less than the width of the sliderTrack.
         * CS 2022-11-10: Also added extra 1px
         */
        const hasNextSlide = sliderTrack.offsetWidth + Math.ceil(sliderTrack.scrollLeft) + 1 < sliderTrack.scrollWidth;
        // this.logger.log('has next slide', hasNextSlide);
        return hasNextSlide;
    }
}

export default Slider;
