'use strict';

export default class ConsentMissingError extends Error {
    /**
     * Create a new instance.
     *
     * @param {string|undefined} message
     * @param {{ templateId: string, acceptedTemplateIds: string[] }} data
     */
    constructor (message, data = {}) {
        super(message);

        /**
         * This is the template ID we’re missing the consent for.
         */
        this.templateId = data.templateId;

        /**
         * The acceptedTemplateIds are a list of already accepted consent (template) IDs.
         * We’re keeping track of them in case the Usercentrics JS isn’t working and not
         * storing the accepted consents. We still want to proceed with the user’s
         * actual intend (e.g. play audio) and store the temporary list for them.
         */
        this.acceptedTemplateIds = data.acceptedTemplateIds || [];
    }
}
