/**
 * Offcanvas
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 */

'use strict';

import ClassLogger from 'ClassLogger';

class Offcanvas {
    getClassName () { return 'Offcanvas'; }
    constructor (navigationHandler) {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.navigationHandler = navigationHandler;

        self.animationDuration = 400; // Attentation: When changing this, change duration in _offcanvas.scss as well!

        // Element to return focus when nav is closed
        self.$returnfocus = null;

        self.navigationHandler.on('ready', () => {
            self.$offcanvas = document.querySelector('.c-offcanvas');
            if (self.$offcanvas) {
                self.init();
            } else {
                self.logger.warn('No offcanvas component found in document.');
            }
        });
    }

    init () {
        const self = this;
        self.logger.log('Offcanvas init');

        self.$offcanvas.classList.add('u-supports-js');

        self.$toggles = new Set([
            ...document.querySelectorAll('a[href="#nav"]'),
            ...document.querySelectorAll('[aria-controls="menu"]'),
        ]);

        // Element to focus when nav is opened (close button)
        self.$firstfocus = self.$offcanvas.querySelector('[aria-controls="menu"]');

        // aria attributes
        self.$offcanvas.setAttribute('aria-hidden', 'true');
        self.$toggles.forEach($toggle => {
            $toggle.setAttribute('aria-expanded', 'false');
            $toggle.setAttribute('aria-controls', 'menu');
        });

        self.resetSublists();

        // click on "backdrop"
        self.$offcanvas.addEventListener('click', (e) => {
            if (e.target !== self.$offcanvas) {
                return;
            }
            e.preventDefault();
            self.close();
            return false;
        });

        // click on menu open/close buttons
        document.addEventListener('click', (e) => {
            const openLink = e.target.closest('a[href="#menu"]');
            if (openLink) {
                e.preventDefault();
                e.stopPropagation();
                self.show();
                return false;
            }

            const closeLink = e.target.closest('.c-offcanvas__close');
            if (closeLink) {
                e.preventDefault();
                e.stopPropagation();
                self.close();
                return false;
            }
        });

        document.addEventListener('keydown', (ev) => {
            if (self.isOpen() && /^Esc(ape)?$/.test(ev.key)) {
                self.close();
            }
        });

        // trap focus when menu is open inside offcanvas
        document.body.addEventListener('focus',
            (ev) => {
                if (self.isOpen() && !ev.target.closest('.c-offcanvas')) {
                    self.$firstfocus.focus();
                }
            },
            { capture: true },
        );

        // click on links that have sublist, hiding eventually open sublists
        const checkboxSelector = '.c-offcanvas__listitem--with-sublist .c-offcanvas__checkbox';
        const checkboxToggles = self.$offcanvas.querySelectorAll(checkboxSelector);
        if (checkboxToggles) {
            checkboxToggles.forEach(element => {
                element.addEventListener('change', (e) => {
                    // self.logger.log('checkbox clicked', e.target);

                    const $sublist = element.parentNode.querySelector('.c-offcanvas__sublist');
                    if (element.checked === true) {
                        $sublist.setAttribute('aria-hidden', 'false');
                        $sublist.setAttribute('aria-expanded', 'true');
                        // self.setStyleProps($sublist, { display: false });
                        setTimeout(() => {
                            element.classList.add('is-active');
                        }, 20);
                    } else {
                        // $sublist.setAttribute('aria-hidden', 'true');
                        $sublist.setAttribute('aria-expanded', 'false');
                        element.classList.remove('is-active');

                        $sublist.addEventListener('transitionend', (e) => {
                            if (e.target === $sublist) {
                                console.log('Transition ended', e.target, e.propertyName);
                                // self.setStyleProps($sublist, { display: 'none' });
                                $sublist.setAttribute('aria-hidden', 'true');
                            }
                        }, { passive: true, once: true });
                    }

                    // TODO: Q: close other sublists when opening another? enable this:
                    /*
                    const targetCheckbox = e.target.closest('.c-offcanvas__checkbox');
                    const checkedItems = self.$offcanvas.querySelectorAll(checkboxSelector + ':checked');
                    checkedItems.forEach(checkedItem => {
                        if (checkedItem !== targetCheckbox) {
                            self.logger.log('unchecking', checkedItem);
                            checkedItem.checked = false;
                        }
                    });
                    */
                });
            });
        }

        // Make some methods public
        window.antenne = window.antenne || {};
        window.antenne.offcanvas = {
            open: () => { self.open(); },
            close: () => { self.close(); },
        };
    }

    toggle () {
        const self = this;
        self.logger.log('Should toggle');
        if (self.$offcanvas.classList.has('is-active')) {
            self.close();
        } else {
            self.show();
        }
    }

    show () {
        const self = this;
        // self.logger.log('Should show');

        if (self.isOpen() === true) {
            return;
        }

        // capture focused element
        self.$returnfocus = document.activeElement;

        self.setStyleProps(document.documentElement, { overflow: 'hidden' });

        self.$toggles.forEach($toggle => {
            $toggle.setAttribute('aria-expanded', 'true');
        });

        self.$offcanvas.classList.add('is-active');
        self.$offcanvas.setAttribute('aria-hidden', 'false');
        setTimeout(() => {
            self.$offcanvas.classList.add('is-animating');
        }, 20);

        // focus must be set, when animation ended, otherwise the offcanvas visually jumps into the view
        setTimeout(() => {
            self.$firstfocus.focus();
        }, self.animationDuration);
    }

    close () {
        const self = this;
        // self.logger.log('Should hide');

        if (self.isOpen() === false) {
            return;
        }

        self.$toggles.forEach($toggle => {
            $toggle.setAttribute('aria-expanded', 'false');
        });

        self.setStyleProps(document.documentElement, { overflow: false });

        // reset focus
        self.$returnfocus.focus();

        self.$offcanvas.classList.remove('is-animating');
        self.$offcanvas.setAttribute('aria-hidden', 'true');

        setTimeout(() => {
            self.$offcanvas.classList.remove('is-active');
            self.resetSublists();
        }, self.animationDuration);
    }

    resetSublists () {
        const self = this;
        const $sublists = self.$offcanvas.querySelectorAll('.c-offcanvas__sublist');
        $sublists.forEach($sublist => {
            $sublist.setAttribute('aria-hidden', 'true');
            $sublist.setAttribute('aria-expanded', 'false');
        });

        const checkboxSelector = '.c-offcanvas__listitem--with-sublist .c-offcanvas__checkbox';
        const checkboxToggles = self.$offcanvas.querySelectorAll(checkboxSelector);
        if (checkboxToggles) {
            checkboxToggles.forEach($checkbox => {
                $checkbox.classList.remove('is-active');
                $checkbox.checked = false;
            });
        }
    }

    isOpen () {
        const self = this;
        return self.$offcanvas.classList.contains('is-active');
    }

    // Helper to apply inline CSS
    setStyleProps ($el, styles) {
        for (const [key, value] of Object.entries(styles)) {
            if (value === false) {
                $el.style.removeProperty(key);
            } else {
                $el.style.setProperty(key, value);
            }
        }
    }
}

export default Offcanvas;
