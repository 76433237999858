'use strict';

import ClassLogger from 'ClassLogger';

export default class WeatherService {
    /**
     * @returns {string}
     */
    getClassName () {
        return 'WeatherRadar';
    }

    /**
     *
     * @param {NavigationHandler} navigationHandler
     * @param {CommonMethods} commonMethods
     */
    constructor (navigationHandler, commonMethods) {
        /** @type {console} */
        this.logger = ClassLogger(this, true);
        this.navigationHandler = navigationHandler;
        this.commonMethods = commonMethods;

        this.baseUrl = '/media/rain-radar/';
        this.images = [];

        this.navigationHandler
            .on('render', () => this.init())
            .on('ready', () => this.init())
        ;
    }

    async init () {
        this.component = document.querySelector('[data-weatherradar]');

        if (!this.component) {
            return;
        }

        // We have a component, let's setup...
        let location = 'de';
        const options = JSON.parse(this.component.dataset.weatherradar);

        if (options && options.location) {
            location = options.location || 'de';
        }

        const imageElement = this.component.querySelector('[data-weatherradar-data]');
        if (!imageElement) {
            this.logger.log('image element not found in markup');
            return;
        }

        let imageList = [];
        try {
            imageList = await this.fetchImageList(location);
            if (!Array.isArray(imageList) || imageList.length === 0) {
                throw new Error('Unexpected response');
            }
        } catch (error) {
            this.logger.error('failed to get image list', error);
            this.component.classList.remove('is-loading');
            return;
        }

        this.component.classList.remove('is-skeleton', 'is-loading');

        /*
         * Here we initialize a 'load' eventlistener and a setTimeout for the tick to the next image.
         * Once a navigation (render) happens, the tick-loop is stopped by an AbortController
         */
        const abortController = new AbortController();
        this.navigationHandler.on('render', () => {
            abortController.abort();
        });

        // initialize loop ("on load" and setTimeout)
        imageElement.addEventListener('load', () => {
            setTimeout(async () => {
                index++;
                if (index === imageList.length) {
                    // on the last image we wait some longer for better UX
                    await this.commonMethods.sleep(2000);
                    index = 0;
                }
                imageElement.src = this.baseUrl + imageList[index];
                this.changeTime(imageList[index]);
            }, this.component.dataset.interval || 500);
        }, {
            passive: true,
            signal: abortController.signal,
        });

        // Load initial image – triggers initially our loop because a 'load' is fired
        let index = 0;
        imageElement.src = this.baseUrl + imageList[index];
        this.changeTime(imageList[index]);
    }

    /**
     * Gets the images from index.json file
     * Handles skeleton rendering as well.
     */
    async fetchImageList (location) {
        const response = await fetch(this.baseUrl + location + '/index.json');
        return await response.json();
    }

    /**
     * Handles timestamp in our image filename and appends the DOM showing the formatted date.
     */
    changeTime (activeImagePath) {
        const pathAsArray = activeImagePath.split('/');
        if (pathAsArray.length === 0) {
            throw new Error('Unexpected image filename format');
        }
        const filenameAsArray = pathAsArray.slice(-1)[0].split('-');
        if (filenameAsArray.length !== 2) {
            throw new Error('Unexpected image filename format');
        }
        const timestamp = filenameAsArray[0];

        if (timestamp) {
            const formattedTime = this.convertTimestampTotime(timestamp);
            const flag = this.component.querySelector('[data-weatherradar-time]');
            if (!flag) {
                this.logger.log('No Flag found to populate.');
                return;
            }

            flag.innerHTML = '';
            flag.append(formattedTime);
            flag.classList.remove('u-hide');
        }
    }

    /**
     * @param {timestamp} timestamp
     * @returns {string}
     */
    convertTimestampTotime (timestamp) {
        // Create a new Date object using the timestamp
        const date = new Date(timestamp * 1000);

        // Get the individual components of the date
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const dayOfWeek = this.getDayOfWeek(date);
        const day = date.getDate();
        const month = date.getMonth() + 1; // Adding 1 since months are zero-based
        const year = date.getFullYear();

        const formattedTime = `${dayOfWeek} ${day}.${month}.${year}
        ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        return formattedTime;
    }

    /**
     * Returns our day of the week
     * @param {Date} date
     * @returns {string}
     */
    getDayOfWeek (date) {
        const daysOfWeek = ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sat.'];
        const dayOfWeekNumber = date.getDay();
        const dayOfWeek = daysOfWeek[dayOfWeekNumber];
        return dayOfWeek;
    }
}
