'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';

class Webcam {
    getClassName () {
        return 'Webcam';
    }

    /**
     *
     * @param {NavigationHandler} navigationHandler
     */
    constructor (navigationHandler) {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disbale logging
        this.navigationHandler = navigationHandler;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    init () {
        const tabs = document.querySelector('.c-tabs--webcams');
        if (!tabs) {
            return;
        }

        const pie = tabs.querySelector('.o-pietimer');
        const circle = pie.querySelector('circle');
        const webcams = tabs.querySelectorAll('img');

        pie.classList.add('u-transition');
        circle.style.strokeDasharray = '50.2654824574';

        circle.addEventListener('transitionend', () => {
            pie.classList.remove('u-transition');
            circle.style.strokeDasharray = '0 50.2654824574';
            if (document.visibilityState !== 'hidden') {
                webcams.forEach(webcam => {
                    const src = webcam.getAttribute('src').split('?')[0] + '?v=' + Date.now();
                    webcam.setAttribute('src', src);
                });
            }
            setTimeout(function () {
                pie.classList.add('u-transition');
                circle.style.strokeDasharray = '50.2654824574';
            }, 100);
        });
    }
}

export default Webcam;
