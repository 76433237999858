'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('mitt').Emitter} EventEmitter
 * @typedef {import('../antenne-api').Metadata} Metadata
 * @typedef {import('./CommonMethods').default} CommonMethods
 */

import ClassLogger from 'ClassLogger';
import { ApiClient } from './ApiClient';

export default class MetadataService {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'MetadataService';
    }

    /**
     * @param {EventEmitter} eventEmitter
     * @param {CommonMethods} commonMethods
     */
    constructor (eventEmitter, commonMethods) {
        /** @protected @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.eventEmitter = eventEmitter;
        this.commonMethods = commonMethods;
        this.audioPlayer = null; // set via setAudioPlayer()

        /**
         * This option is used to store the interval in seconds that is used to poll
         * metadata for all stations from the Antenne API.
         */
        this.metadataHttpPollIntervalInSeconds = 90;
        this.metadataHttpPollTimer = undefined;
        this.shouldStopPolling = false;

        /** @type {Metadata[]} */
        this.lastPolledMetadata = [];
        this.apiClient = new ApiClient();
        this.pollCounter = 0;

        if (document.documentElement.classList.contains('template--accountminimal')) {
            return;
        }

        this.startPolling();
    }

    setAudioPlayer (instance) {
        this.audioPlayer = instance;
    }

    /**
     * Start polling the metadata API endpoint for information what’s currently
     * being played on all stations. This method dispatches an event with an
     * array of metadata items for all stations.
     *
     * @returns {this}
     */
    startPolling () {
        this.pollCounter = 0;
        this.shouldStopPolling = false;
        this.fetchAndEmitMetadata();
        return this;
    }

    /**
     * Returns the milliseconds until for the poll interval.
     *
     * @returns {Number}
    */
    getPollIntervalInMilliseconds () {
        /**
         * This random offset distributes client requests within the given interval.
         * By using a hard, exact amount of millseconds we would possibly DDoS our
         * API because all clients would send metadata requests to the same time.
         */
        const offsetInMilliseconds = this.commonMethods.randomIntWithin(0, 5000);
        return this.metadataHttpPollIntervalInSeconds * 1000 + offsetInMilliseconds;
    }

    /**
     * @returns {this}
     */
    stopPolling () {
        this.shouldStopPolling = true;
        if (this.metadataHttpPollTimer) {
            clearTimeout(this.metadataHttpPollTimer);
        }
        this.metadataHttpPollTimer = undefined;
        return this;
    }

    /**
     * Tba.
     *
     * @returns {void}
     *
     * @private
     */
    async fetchAndEmitMetadata () {
        try {
            let data = [];
            // every 3rd request we poll all metadata, otherwise only current selected channel
            if (this.pollCounter % 3 !== 0 && this.audioPlayer !== null) {
                if (
                    this.audioPlayer.isPlaying() ||
                    this.audioPlayer.mediaType !== 'channel'
                ) {
                    // When player is playing or the (paused) media is not a channel, we don't need to poll a mountpoint
                    data = [];
                    this.logger.log('skipping metadata fetch because not needed');
                } else {
                    const channelkey = this.audioPlayer.mediaId;
                    const channel = await this.audioPlayer.channelConfigByChannelKey(channelkey);
                    const mountpoint = channel.stream.mountpoint;
                    this.logger.log('fetching metadata for mountpoint', { channelkey, mountpoint });
                    data = [await this.apiClient.get(
                        '/metadata/now/' + mountpoint,
                        { credentials: 'omit' },
                    )];
                }
            } else {
                this.logger.log('fetching metadata for all mountpoints');
                data = await this.apiClient.get('/metadata/now', { credentials: 'omit' });
            }

            // update lastPolledMetadata with the fetched channel(s) and keep non-fetched channels from previous
            data.forEach(newItem => { // ...for all fetched channels
                // ...update the channel in lastPolledMetadata
                let found = false;
                this.lastPolledMetadata = this.lastPolledMetadata.map(oldItem => {
                    if (oldItem.mountpoint === newItem.mountpoint) {
                        found = true;
                        return newItem;
                    }
                    return oldItem;
                });
                if (found === false) {
                    this.lastPolledMetadata.push(newItem);
                }
            });
            this.eventEmitter.emit('metadata:now', this.lastPolledMetadata);
        } catch (error) {
            this.logger.warn('Failed to fetch metadata', { error });
            // we do not reset lastPolledMetadata here, we keep the existing
        }

        this.pollCounter++;

        if (this.shouldStopPolling !== true) {
            const nextTimeout = this.getPollIntervalInMilliseconds();
            this.logger.log('setting next timeout for polling', { next: new Date(Date.now() + nextTimeout) });
            // set next timeout
            this.metadataHttpPollTimer = setTimeout(() => {
                this.fetchAndEmitMetadata();
            }, nextTimeout);
        }
    }

    /**
     * Returns a metadata object for the given `mountpoint`. Returns `undefined`
     * if no metadata is available.
     *
     * @param {String} mountpoint
     *
     * @returns {Metadata | undefined}
     */
    getByMountpoint (mountpoint) {
        return this.lastPolledMetadata.find(item => {
            return item.mountpoint === mountpoint;
        });
    }
}
