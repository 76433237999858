'use strict';

import ClassLogger from 'ClassLogger';
import { ApiClient } from './ApiClient';

export default class WeatherService {
    /**
     * @returns {string}
     */
    getClassName () {
        return 'WeatherService';
    }

    /**
     *
     * @param {NavigationHandler} navigationHandler
     * @param {CommonMethods} commonMethods
     */
    constructor (navigationHandler, commonMethods) {
        /** @type {console} */
        this.logger = ClassLogger(this, true);
        this.apiClient = new ApiClient();
        this.navigationHandler = navigationHandler;
        this.commonMethods = commonMethods;

        this.navigationHandler
            .on('render', () => this.init())
            .on('ready', () => this.init())
        ;
    }

    async init () {
        const searchElement = document.querySelector('[data-weatherlocation="input"]');
        const autocompleteElement = document.querySelector('[data-weatherlocation="results"]');
        if (!searchElement || !autocompleteElement) {
            return;
        }
        this.logger.log('Initializing', searchElement);

        const self = this;
        let previousRequest = null;
        // add event listeners to search input(s)
        searchElement.addEventListener(
            'input',
            this.commonMethods.debounce(
                async (e) => {
                    const searchQuery = e.target.value;
                    // zipcodes (only numbers) are only found when they are entered completely, so we can escape early
                    if (!isNaN(parseInt(searchQuery)) && searchQuery.length !== 5) {
                        return;
                    }
                    if (previousRequest) {
                        previousRequest.abort();
                    }
                    previousRequest = this.apiClient.get('/weather/search?s=' + searchQuery);
                    previousRequest.then(cities => {
                        self.renderCities(cities, searchElement, autocompleteElement);
                    }).catch(error => {
                        self.logger.error('Failed getting cities', error);
                    });
                },
            ),
        );
    }

    /**
     * @param {LocationCity[]} cities
     * @param {HTMLElement} searchElement
     * @param {HTMLElement} autocompleteElement
     */
    renderCities (cities, searchElement, autocompleteElement) {
        this.logger.log('Should render cities', cities);
        autocompleteElement.innerHTML = '';

        if (cities.length === 0) {
            autocompleteElement.classList.add('u-hide');
            return;
        }
        const citiesCount = cities.length;
        autocompleteElement.append(
            this.commonMethods.markupToElement(`
                    <li>
                       <span>Bitte einen Ort aus der Liste auswählen (${citiesCount}):</span>
                    </li>
                `),
        );
        cities.forEach(location => {
            const countrycode = location.country_code.toUpperCase();
            autocompleteElement.append(
                this.commonMethods.markupToElement(`
                    <li>
                        <a href="${location.weather_link}">${location.city}, ${location.state} (${countrycode})</a>
                    </li>
                `),
            );
        });
        autocompleteElement.classList.remove('u-hide');
    }
}
