'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('../openapi-generated').Channel} Channel
 * @typedef {{ expireson: number, data: Channel[] }} ChannelCache
 */

import ClassLogger from 'ClassLogger';
import { ApiClient } from './ApiClient';

export default class ChannelService {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'ChannelService';
    }

    /**
     * Create a new instance.
     */
    constructor () {
        /** @protected @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.cacheKey = 'channel:all';
        this.cacheTtlInSeconds = 10 * 60; // 10min

        this.apiClient = new ApiClient();
    }

    /**
     * Fetch channel data from the API and cache it in local storage.
     *
     * @returns {Promise<Channel[]>}
     */
    async fetchChannelsFromApi () {
        /** @type {Channel[]} */
        const channels = await this.apiClient.get('/channels', { credentials: 'omit' });

        localStorage.setItem(this.cacheKey, JSON.stringify({
            expireson: Date.now() + this.cacheTtlInSeconds * 1000,
            data: channels,
        }));

        return channels;
    }

    /**
     * Returns the channel data for the given `channelkey`.
     *
     * @param {String} channelkey
     *
     * @returns {Promise<Channel>}
     */
    async getChannel (channelkey) {
        let channel = (await this.getChannels()).find(channel => channel.channelkey === channelkey);
        if (channel) {
            return channel;
        }

        // maybe cache is outdated, fetch again to ensure fresh data
        channel = (await this.fetchChannelsFromApi()).find(channel => channel.channelkey === channelkey);
        if (channel) {
            return channel;
        }

        throw new Error(`Channel '${channelkey}' not found`);
    }

    async getChannels () {
        /** @type {ChannelCache} */
        let { expireson, data: channels = [] } = JSON.parse(localStorage.getItem(this.cacheKey) || '{}');

        if (!expireson || expireson < Date.now()) {
            try {
                channels = await this.fetchChannelsFromApi();
            } catch (error) {
                this.logger.error('Failed to fetch channels from api', { error });
            }
        }

        return channels;
    }
}
