'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./Tooltip').default} Tooltip
 * @typedef {import('../openapi-generated').DefaultBookmark} DefaultBookmark
 * @typedef {import('../antenne-frontend').NativeJsBridge} NativeJsBridge
 * @typedef {import('../antenne-frontend').BookmarkDataAttribute} BookmarkDataAttribute
 * @typedef {{ expireson?: number, userId: string, data: DefaultBookmark[] }} BookmarkCache
 * @typedef {import('./TrackingService').default} TrackingService
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./Slider').default} Slider
 * @typedef {import('./WebshareUI').default} WebshareUI
 * @typedef {import('./ChannelCurrentlyPlaying').default} currentlyPlayingChannelkey
 */

import ClassLogger from 'ClassLogger';
import { ApiClient } from './ApiClient';
import { ApiError } from './ApiError';

export default class BookmarkService {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'BookmarkService';
    }

    /**
     * Create a new instance.
     * @param {TrackingService} trackingService
     * @param {EventEmitter} eventEmitter
     * @param {CommonMethods} commonMethods
     * @param {Slider} sliderService
     * @param {Tooltip} tooltip
     * @param {WebshareUI} webShareUI
     * @param {ChannelCurrentlyPlaying} channelCurrentlyPlaying
     */
    constructor (
        commonMethods,
        eventEmitter,
        trackingService,
        sliderService,
        tooltip,
        webShareUI,
        channelCurrentlyPlaying) {
        /** @protected @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.eventEmitter = eventEmitter;
        this.trackingService = trackingService;
        this.commonMethods = commonMethods;
        this.sliderService = sliderService;
        this.webShareUI = webShareUI;
        this.tooltip = tooltip;
        this.channelCurrentlyPlaying = channelCurrentlyPlaying;

        /** @private */
        this.localStorageCacheKey = 'user.bookmarks';

        /** @private */
        this.userId = undefined;

        /** @private */
        this.cacheTtlInSeconds = 10 * 60; // 10min

        /** @private */
        this.apiClient = new ApiClient();

        /** @type {NativeJsBridge} */
        this.nativeJsBridge = window.nativeJsBridge;

        this.fetchPromise = null;
    }

    /**
     * Stores the user ID which personalizes bookmarks in local storage.
     *
     * @param {String} uuid
     */
    setUserId (uuid) {
        this.userId = uuid;

        try {
            this.validateCache();
        } catch (error) {
            this.logger.error('Failed to validate bookmark cache for current user. Leaving it untouched', { error });
        }
    }

    /**
     * @param {String} uuid
     */
    validateCache () {
        const bookmarks = this._getBookmarkDataFromLocalStorage();

        if (bookmarks.userId !== this.userId) {
            this.clearBookmarksDataFromLocalStorage();
        }
    }

    /**
     * Determine whether the given bookmark data `attributes` are valid.
     *
     * @param {BookmarkDataAttribute} data
     *
     * @returns {Boolean}
     */
    isValidBookmarkData (data) {
        return !!data && typeof data === 'object' && !!data.category && !!data.content_id;
    }

    /**
     * Create a new bookmark for the logged-in user.
     *
     * @param {BookmarkDataAttribute} data
     *
     * @returns {Promise<DefaultBookmark>}
     */
    async addBookmark (data) {
        if (!this.isValidBookmarkData(data)) {
            throw new Error('Bookmark data incomplete');
        }
        const bookmark = await this.apiClient.post(`/bookmarks/${data.category}`, {
            content_id: data.content_id,
        });

        this.saveBookmarksToLocalStorage(
            // add new bookmark to the beginning
            [].concat([bookmark], await this.getBookmarks()),
        );

        this.trackingService.track('bookmark:add', {
            identifier: data.content_id,
            category: data.category,
        });

        // doing this async...
        // sending the 'bookmark' object incl. 'content' to native
        this.nativeJsBridge
            .callHandlerIfWebview('bookmark.add', bookmark)
            .catch(error => {
                this.logger.error('Received error while sending "bookmark.add" to native app', { error, data });
            });

        return bookmark;
    }

    /**
     * Delete a bookmark identified by the given `data` for the logged-in user.
     *
     * @param {BookmarkDataAttribute} data
     * @param {Node} button
     */
    async removeBookmark (data) {
        if (!this.isValidBookmarkData(data)) {
            throw new Error('Bookmark data incomplete');
        }

        this.saveBookmarksToLocalStorage(
            (await this.getBookmarks()).filter(bookmark => {
                return bookmark.content_id !== data.content_id || bookmark.category !== data.category;
            }),
        );

        await this.apiClient.delete(`/bookmarks/${data.category}/${data.content_id}`);

        this.trackingService.track('bookmark:delete', {
            identifier: data.content_id,
            category: data.category,
        });

        // doing this async...
        this.nativeJsBridge
            .callHandlerIfWebview('bookmark.delete', data)
            .catch(error => {
                this.logger.error('Received error while sending "bookmark.delete" to native app', { error, data });
            });
    }

    /**
     * Check bookmark identified by the given `data` for the logged-in user.
     *
     * @param {BookmarkDataAttribute} data
     *
     * @returns {Promise<Boolean>}
     */
    async isBookmarked (data) {
        if (!this.isValidBookmarkData(data)) {
            throw new Error('Bookmark data incomplete');
        }
        return (await this.getBookmarks()).some(bookmark => {
            return bookmark.content_id === data.content_id && bookmark.category === data.category;
        });
    }

    /**
     * Returns the list of bookmarks for the authenticated user.
     *
     * @returns {Promise<DefaultBookmark[]>}
     */
    async fetchBookmarks () {
        if (this.fetchPromise !== null) {
            // prevent sending multiple parallel fetch requests
            return this.fetchPromise;
        }

        this.logger.log('Sending GET request to fetch bookmarks for user');

        this.fetchPromise = this.apiClient.get('/bookmarks').then((bookmarks) => {
            this.fetchPromise = null;
            /** @var {DefaultBookmark[]} bookmarks */
            this.saveBookmarksToLocalStorage(bookmarks, this.cacheTtlInSeconds);
            return bookmarks;
        }).catch((error) => {
            if (
                error instanceof ApiError &&
                error.statusCode === 401 &&
                error.code === 'authentication-error'
            ) {
                // let auth-errors bubble up (e.g. to show regwall for bookmarks)
                throw error;
            }
            this.logger.error(error);

            const oldCachedData = this.getCachedBookmarks();

            this.saveBookmarksToLocalStorage(
                this.getCachedBookmarks(), 30,
            );

            return oldCachedData;
        });
        return this.fetchPromise;
    }

    /**
     * Returns the bookmarks for the logged-in user stored in local storage.
     *
     * @returns {BookmarkDataAttribute[]}
     */
    getCachedBookmarks () {
        const { data: bookmarks = [] } = this._getBookmarkDataFromLocalStorage();
        return bookmarks;
    }

    /**
     * Gets our bookmarks. Looks for local storage first
     * @returns
     */
    getBookmarks () {
        const { data: bookmarks = [], expireson } = this._getBookmarkDataFromLocalStorage();

        if (expireson && expireson > Date.now()) {
            return bookmarks;
        }

        return this.fetchBookmarks();
    }

    /**
     * Returns the bookmark cache entry from local storage.
     *
     * @returns {BookmarkCache}
     */
    _getBookmarkDataFromLocalStorage () {
        return JSON.parse(
            localStorage.getItem(this.localStorageCacheKey) || '{}',
        );
    }

    /**
     * Delete the bookmark cache entry from local storage.
     * For example, this happens when a user logs out.
     */
    clearBookmarksDataFromLocalStorage () {
        localStorage.removeItem(this.localStorageCacheKey);
    }

    /**
     * Stores the given `bookmarks` in local storage.
     *
     * @param {BookmarkDataAttribute[]} bookmarks
     *
     * @returns {this}
     */
    saveBookmarksToLocalStorage (bookmarks, ttlInSeconds = null) {
        // expireson is only refreshed if instructed to do so (e.g. when data is fetched from api)
        let expireson = 0;
        if (ttlInSeconds === null) {
            const previousData = this._getBookmarkDataFromLocalStorage();
            if (previousData.expireson) {
                expireson = previousData.expireson;
                this.logger.log('using previous expireson for save', { expireson });
            } else {
                ttlInSeconds = this.cacheTtlInSeconds;
            }
        }
        if (expireson === 0) {
            expireson = Date.now() + ttlInSeconds * 1000;
        }
        this.logger.log('saving bookmarks', { expireson, ttlInSeconds });
        localStorage.setItem(this.localStorageCacheKey, JSON.stringify({
            expireson,
            data: bookmarks,
            userId: this.userId,
        }));

        return this;
    }

    /**
     * Renders a bookmark widget
     * @param {Node} element
     * @param {String} category
     * @param {String} layout
     */
    async renderBookmarkWidget (element, category, layout) {
        let bookmarks = await this.getBookmarks();

        if (category) {
            bookmarks = bookmarks.filter(bookmark => {
                return bookmark.category === category;
            });
        }

        if (bookmarks.length === 0) {
            this.renderNoBookmarksMessage(element, layout);
            return;
        }

        // Limit our array to 30 items
        bookmarks = bookmarks.slice(0, 30);

        switch (layout) {
            case 'coverslider':
                this.renderCoverSlider(bookmarks, element, category);
                break;
            case 'cardslider':
                this.renderCardSlider(bookmarks, element);
                break;
            case 'grid':
                await this.renderGrid(bookmarks, element, category);
                break;
            default:
                this.logger.error('Unknown bookmark widget layout specified', { layout });
        }

        // We have results, so remove the noresult-link if it exists
        this.removeNoResultsLink(element);

        this.eventEmitter.emit('user:bookmarks:handleButtons');
    }

    /**
     * Removes in the bookmark widget the link to no results landingpage
     * @param {Node} element
     */

    removeNoResultsLink (element) {
        const noResultsLink = element.querySelector('[data-bookmark-noresults-link]');
        if (noResultsLink) {
            noResultsLink.remove();
        }
    }

    /**
     * Accepts an image array from the bookmarks api and returns an array with
     * best fit image from the array
     * @param {Array} image
     * @param {String} smallWebP
     * @param {String} largeWebP
     * @param {String} fallback
     * @returns
     */
    generateCoverImageUrls (image, smallWebP, largeWebP, fallback) {
        return {
            cover_small_webp: this.commonMethods.findMatchingImage(image, smallWebP, '.webp'),
            cover_big_webp: this.commonMethods.findMatchingImage(image, largeWebP, '.webp'),
            cover_small_jpg: this.commonMethods.findMatchingImage(image, fallback, '.jpg'),
        };
    }

    /**
     * Handles the rendering of cover sliders.
     * @param {Array} bookmarks
     * @param {Node} element
     * @param {String} category
     * @returns
     */
    renderCoverSlider (bookmarks, element, category) {
        const sliderTrack = element.querySelector('.c-slider__track');
        // Empty to repopulate. TextContent is more efficient than innerHTML (MDN docs)
        sliderTrack.textContent = '';

        // limit rendered bookmarks to 30
        bookmarks.forEach(bookmark => {
            // Handle category based items
            let images = [];
            let playType = '';
            let playId = '';
            switch (category) {
                case 'channels':
                    images = this.generateCoverImageUrls(bookmark.content.image, '300x300', '300x300', '300x300');
                    playType = bookmark.category.slice(0, -1);
                    playId = bookmark.content_id;
                    break;
                case 'podcasts':
                case 'shows':
                    images = this.generateCoverImageUrls(bookmark.content.image, '300x300', '300x300', '300x300');
                    break;
                case 'songs':
                    images = this.generateCoverImageUrls(bookmark.content.image, '300x300', '300x300', '600x600');
                    playType = bookmark.category.slice(0, -1);
                    playId = bookmark.content_id;
                    break;
                default:
                    this.logger.log('Given category is not handled.');
            }

            const renderItem = this.commonMethods.markupToElement(window.antenne.templates.cover({
                ...images,
                ...bookmark.content,
                bookmark: JSON.stringify({ category: bookmark.category, content_id: bookmark.content_id }),
                playType,
                playId,
            }));

            // Check if we have a play button. For use in two places.
            const playBtn = renderItem.querySelector('.c-cover__button--play');

            // Songs don't have landing pages, remove this link DOM el.
            if (category === 'songs') {
                const link = renderItem.querySelector('.u-faux-block-link-overlay');
                if (link) {
                    link.remove();
                }

                if (!bookmark.content.hook && playBtn) {
                    playBtn.remove();
                }
            }

            // Podcasts/shows don't have a general stream to play, remove this button DOM el.
            if (category === 'shows' || category === 'podcasts') {
                if (playBtn) {
                    playBtn.remove();
                }
            }

            sliderTrack.appendChild(renderItem);
        });

        this.renderSlider(element, sliderTrack, '.c-slider');
    }

    /**
     * Handles card sliders used for articles
     * @param {Array} bookmarks
     * @param {Node} element
     */
    renderCardSlider (bookmarks, element) {
        const sliderTrack = element.querySelector('.l-cardgrid__items');

        sliderTrack.textContent = '';

        bookmarks.forEach(bookmark => {
            if (!bookmark.content.summary) {
                // undefined items render as undefined in frontend.
                bookmark.content.summary = '';
            }
            let images = {};
            if (bookmark.content.image) {
                images = {
                    largeThumb: this.commonMethods.findMatchingImage(bookmark.content.image, '512x288', '.webp'),
                    smallThumb: this.commonMethods.findMatchingImage(bookmark.content.image, '320x180', '.webp'),
                    fallbackThumb: this.commonMethods.findMatchingImage(bookmark.content.image, '320x180', '.jpg'),
                };
            }

            const renderItem = this.commonMethods.markupToElement(window.antenne.templates.card({
                ...images,
                content_id: bookmark.content_id,
                heading: bookmark.content.title,
                description: bookmark.content.summary,
                like_type: 'news',
                altText: bookmark.content.title,
                imageWidth: 320,
                imageHeight: 180,
                link: bookmark.content.link,
                sharetext: bookmark.content.summary,
            }));

            // Remove markup if no images
            if (!bookmark.content.image) {
                const figure = renderItem.querySelector('.c-image');
                figure.remove();
            }

            sliderTrack.appendChild(renderItem);
        });

        this.renderSlider(element, sliderTrack, '.l-cardgrid--largeslider');

        const tooltips = element.querySelectorAll('[data-tooltip]');
        tooltips.forEach(tooltip => {
            this.tooltip.initClick(tooltip);
        });
    }

    /**
     * Renders if no bookmarks for a category are present.
     * @param {Node} element
     */
    renderNoBookmarksMessage (element, layout) {
        element.classList.add('is-skeleton--emptybookmarks');
        element.classList.remove('is-loading');

        if (layout === 'grid') {
            const sliderContainer = element.querySelector('[data-bookmark-audioslider]');
            sliderContainer.classList.remove('is-skeleton', 'is-loading');
        }
    }

    /**
     * Helper function to render a slider.
     * @param {Node} element
     * @param {Node} sliderTrack
     * @param {String} sliderClass
     */
    renderSlider (element, sliderTrack, sliderClass) {
        const slider = element.querySelector(sliderClass);
        slider.classList.remove('is-initialized');
        slider.textContent = '';
        slider.appendChild(sliderTrack);

        this.sliderService.handleSlider(slider);
        element.classList.remove('is-skeleton', 'is-loading');
    }

    /**
     *
     * @param {Array} bookmarks
     * @param {Node} element
     * @param {String} category
     */
    async renderGrid (bookmarks, element, category) {
        let renderItem = {};

        // Rendering our main cover item - larger content
        switch (category) {
            case 'channels':
                renderItem = this.renderMainChannel(bookmarks.shift());
                // Complete rendering happens below the switch case because var declarations aren't allowed here
                break;
            case 'podcasts':
            case 'shows':
                renderItem = await this.renderFirstEpisodeSlider(bookmarks);
                this.renderSlider(element, renderItem, '[data-latestepisode-slider]');
                break;
            default:
                this.logger.log('Given category is not handled.');
        }

        if (!renderItem) {
            this.logger.info('No main element was able to be rendered.');
            return;
        }

        if (category === 'channels') {
            // Add our rendered main channel item to our widget
            const audioitemContainerNode = element.querySelector('[data-bookmark-audioitem]');
            if (!audioitemContainerNode) {
                this.logger.info('No bookmark audio item was found.');
                return;
            }
            audioitemContainerNode.textContent = '';
            audioitemContainerNode.appendChild(renderItem);
        }

        //  Render now the rest of the bookmarks in a slider
        const sliderContainer = element.querySelector('[data-bookmark-audioslider]');
        if (!sliderContainer) {
            this.logger.info('Slider Container was found.');
            return;
        }

        if (bookmarks.length > 0) {
            this.renderCoverSlider(bookmarks, sliderContainer, category);
        } else {
            sliderContainer.classList.remove('is-loading');
            sliderContainer.classList.add('is-skeleton--emptybookmarks');
        }

        /**
         * Show our rendered content on the screen
         * - Remove the favorite button on the main cover item
         * - Remove skeleton styling
         * - Remove the u-hide
         * - Remove the fav buttons in our main cover
         * - Trigger websharing component
         * - Trigger the ChannelCurrentlyPlaying method
         */

        element.classList.remove('is-skeleton', 'is-loading');
        const hiddenElements = element.querySelectorAll('.u-hide');

        hiddenElements.forEach(el => {
            el.classList.remove('u-hide');
        });

        const defaultElements = element.querySelectorAll('.is-default-content');
        defaultElements.forEach(el => {
            el.remove();
        });

        // Remove the bookmark buttons on main covers to follow screen design
        switch (category) {
            case 'channels' :
                this.removeBookmarkButtonOnMainCover(element, '[data-bookmark-audioitem]');
                break;
            case 'podcasts' :
            case 'shows' :
                this.removeBookmarkButtonOnMainCover(element, '[data-latestepisode-slider]');
                break;
            default:
                this.logger.log('Given category is not handled.');
        }

        // Trigger the share button component
        this.webShareUI.initShareButton();

        /**
         *  Get our selected channel to render currently played song info.
         *  If we have a channel widget
         *  @type {Promise<HTMLElement[]>}
         */

        if (category === 'channels') {
            this.channelCurrentlyPlaying.currentlyPlayingTags = new Promise((resolve, reject) => {
                this.channelCurrentlyPlaying.findChannelCurrentlyPlayingTags().then(tags => resolve(tags));
            });

            this.channelCurrentlyPlaying.assignLastPlayingMetadata();
        }
    }

    /**
     * Accepts the element which is the component
     * and the selector which is the container for the fav buttons
     * @param {Node} element
     * @param {string} selector
     */
    removeBookmarkButtonOnMainCover (element, selector) {
        const coverContainer = element.querySelector(selector);
        if (!coverContainer) {
            return;
        }
        const covers = coverContainer.querySelectorAll('.c-cover');
        covers.forEach(cover => {
            const favButton = cover.querySelector('.c-cover__button--favorite');
            if (favButton) {
                cover.removeChild(favButton);
            }
        });
    }

    /**
     * Audio widget main channel rendering
     * @param {Array} channel
     */
    renderMainChannel (channel) {
        let images = {};
        if (channel.content.image) {
            images = this.generateCoverImageUrls(channel.content.image, '300x300', '300x300', '300x300');
        }

        return this.commonMethods.markupToElement(window.antenne.templates.audioitem.channels({
            ...images,
            ...channel.content,
            bookmark: JSON.stringify({ category: channel.category, content_id: channel.content_id }),
            playType: 'channel',
            playId: channel.content_id,
            channel: channel.content_id,
            metadata: '{}',
            playstream: '{}',
            description: channel.content.summary,
            sharetext: channel.content.summary,
            sharetitle: channel.content.title,
        }));
    }

    /**
     * Create our podcast episodes slider
     * @param {Array} channel
     */
    async renderFirstEpisodeSlider (shows) {
        const outputElement = document.createElement('div');
        outputElement.classList.add('c-slider__track');

        for (const show of shows) {
            const latestEpisode = show.content.latest_episode;
            if (latestEpisode) {
                let images = {};
                if (show.content.image) {
                    images = this.generateCoverImageUrls(show.content.image, '300x300', '300x300', '300x300');
                }

                const metadata = {
                    title: latestEpisode.title,
                    artist: show.content.title,
                    cover: this.commonMethods.findMatchingImage(show.content.image, '300x300', '.webp'),
                };

                const playstream = {
                    mp3: latestEpisode.stream.mp3,
                    required_consents: latestEpisode.required_consents,
                };

                const renderItem = this.commonMethods.markupToElement(window.antenne.templates.audioitem.shows({
                    ...images,
                    title: latestEpisode.title,
                    podcastname: show.content.title,
                    altText: latestEpisode.title,
                    bookmark: JSON.stringify({ category: 'shows', content_id: show.content_id }),
                    playType: 'podigee',
                    playId: latestEpisode.id,
                    description: latestEpisode.description,
                    sharetext: latestEpisode.description,
                    sharetitle: latestEpisode.title,
                    link: latestEpisode.link,
                    duration: this._podcastTimeConverter(parseInt(latestEpisode.duration)),
                    metadata: JSON.stringify(metadata),
                    playstream: JSON.stringify(playstream),
                }));

                outputElement.appendChild(renderItem);
            }
        }

        return outputElement;
    }

    _podcastTimeConverter (time) {
        const minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;
        seconds = (seconds < 10 ? '0' : '') + seconds;

        return minutes + ':' + seconds;
    }
}
