/**
 * SiteSelect Class
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 */

'use strict';

import ClassLogger from 'ClassLogger';

class StationSelectDialog {
    getClassName () { return 'StationSelectDialog'; }
    constructor (commonMethods, navigationHandler, eventEmitter, dialogService) {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.commonMethods = commonMethods;
        self.navigationHandler = navigationHandler;
        self.eventEmitter = eventEmitter;
        self.dialogService = dialogService;
        self.contextkey = window.antenne.config.contextkey;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    init () {
        const self = this;

        // Basic button handling of footer
        const stationSelectButtons = document.querySelectorAll('[data-stationselect-open]');
        if (stationSelectButtons.length === 0) {
            return;
        }

        stationSelectButtons.forEach(button => {
            button.addEventListener('click', (e) => {
                self.openStationSelectDialog();
            });
        });

        /**
         * If we are on the rockantenne homepage, check if we should automatically show dialog
         * OR if we should forward the user to their selection
         */

        if (self.contextkey === 'web-rockantenne-de') {
            if (window.location.pathname === '/') {
                self.checkStationSelection();
                self.checkToShowDialog();
            }
        }
    }

    /**
     * If we have a selected station, redirect the user to that station.
     * However, if we have a stationRedirect in the URL, save new host in local storage
     */
    checkStationSelection () {
        const self = this;

        // check if the user has used the back button. If they did, we want to ignore the stationRedirct url param
        // source https://stackoverflow.com/a/59641226
        let checkStationRedirect = true;
        const perfEntries = performance.getEntriesByType('navigation');
        if (perfEntries.length && perfEntries[0].type === 'back_forward') {
            // user used back/forward buttons
            checkStationRedirect = false;
        }

        if (self.getStationRedirectQueryParameter() === 1 && checkStationRedirect === true) {
            // If I have redirected here, then set my selected station in local storage
            self.setStationSelection(window.location.hostname);
        }

        // If we have not redirected here using dialog and we have selected before, forward us
        // We also want to avoid an infinite loop - we check if our current hostname does not equal the
        // selected host.
        const stationSelectedHost = self.getStationSelection();
        if (stationSelectedHost !== null && window.location.hostname !== stationSelectedHost) {
            self.forwardToStation(stationSelectedHost);
        }
    }

    /**
     * Button handler assigns evenListener to each website link in the dialog popup
     * If clicked, saves this in the local storage.
     * @param id // is the id used in the Dialog
     */

    selectButtonHandler (id) {
        const self = this;
        const dialogNode = document.getElementById('dialog-' + id);
        if (dialogNode === null) {
            self.logger.log('Dialog does not exist.');
            return;
        }

        const buttons = dialogNode.querySelectorAll('[data-stationselect-target]');
        buttons.forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                const linkTarget = e.target.href;
                const url = new URL(linkTarget);
                const host = url.hostname;
                self.setStationSelection(host); // save to local storage

                if (host !== window.location.host) {
                    self.navigationHandler.startProgressBar();
                    self.forwardToStation(host); // go to the selected page
                } else {
                    self.stationSelectObject.hide();
                    return false;
                }
            });
        });
    }

    /**
     * Checks the local storage value to control visibility of dialog.
     * Also checks for a stationRedirect param in the url and suppresses the dialog.
     */

    checkToShowDialog () {
        const self = this;
        const hasSelection = self.getStationSelection();
        const stationRedirect = self.getStationRedirectQueryParameter();

        if (hasSelection !== null || stationRedirect === 1) {
            // Don't show wall
        } else {
            self.openStationSelectDialog();
        }
    }

    openStationSelectDialog () {
        const self = this;
        if (!self.stationSelectObject) {
            const id = (Math.random() + 1).toString(36).substring(7);
            const dialogNode = self.commonMethods.markupToElement(
                window.antenne.templates.stationselect(id),
            );
            document.body.append(dialogNode);
            self.stationSelectObject = self.dialogService.initDialogNode(dialogNode, true);
            self.selectButtonHandler(id);
        }
        self.stationSelectObject.show();
    }

    getStationSelection () {
        return window.localStorage.getItem('stationselect_host');
    }

    setStationSelection (host) {
        window.localStorage.setItem('stationselect_host', host);
    }

    getStationRedirectQueryParameter () {
        const url = new URL(window.location.href);
        return parseInt(url.searchParams.get('stationRedirect'));
    }

    forwardToStation (host) {
        let url = `https://${host}/?`;
        url += `stationRedirect=1&utm_source=${window.location.hostname}`;
        url += '&utm_medium=dialog&utm_campaign=station_selection';
        window.location = url;
    }
}

export default StationSelectDialog;
