'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';

class Accordion {
    getClassName () {
        return 'Accordion';
    }

    /**
     * Create a new instance.
     * @param {NavigationHandler} navigationHandler
     */
    constructor (navigationHandler) {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.navigationHandler = navigationHandler;

        this.maxDuration = 0.7;
        this.minDuration = 0.2;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    init () {
        const self = this;
        const components = document.querySelectorAll('.c-accordion__content');
        if (components.length === 0) {
            return;
        }

        if (!this.observer) {
            // Initialize Observer
            this.observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(async (entry) => {
                    if (entry.isIntersecting) {
                        // element is now visiable, re-calc style-properties and stop observing
                        observer.unobserve(entry.target);
                        self.setStyleProperties(entry.target);
                    }
                });
            }, {
                root: null,
            });
        }

        components.forEach((componentContent) => {
            if (self.setStyleProperties(componentContent) === 0) {
                // element is not visible (heigh=0), observe if it becomes visiable later:
                self.observer.observe(componentContent, { attributes: true, childList: true });
            }
        });
    }

    setStyleProperties (componentContent) {
        const innerContent = componentContent.querySelector('.c-accordion__contentinner');
        if (!innerContent) {
            return -1;
        }

        const maxHeight = innerContent.offsetHeight;
        const duration = Math.max(
            this.minDuration,
            Math.min(
                Math.round(maxHeight * 0.008) * 0.1,
                this.maxDuration,
            ),
        );
        componentContent.style.setProperty('--max-height', maxHeight);
        componentContent.style.setProperty('--duration', duration + 's');
        return maxHeight;
    }
}

export default Accordion;
