'use strict';

export default class GeolocationPermissionError extends Error {
    /**
     * Create a new instance.
     *
     * @param {string|undefined} message
     * @param {string} permissionName
     * @param {string} permissionState
     */
    constructor (message, state) {
        super(message);
        this.state = state;
    }

    toString () {
        if (
            this === null ||
            (typeof this !== 'object' && typeof this !== 'function')
        ) {
            throw new TypeError();
        }
        return `${this.name}: ${this.message} (${this.state})`;
    }
}
