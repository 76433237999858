'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';

/**
 * The "ShowAll" component allows you to render and hide content that you want to
 * show when a user clicks on a "show all" button.
 *
 * How it works: add a `data-showall="<unique-id>"` to your button component. The
 * initially hidden elements you want to show when clicking the given "show all"
 * button must carry a `data-showall-belongsto="<same-unique-id>"` attribute.
 *
 * @example
 * Here’s how you set up your HTML markup to make this component work:
 *
 * ```html
 * <button data-showall="1234">Show All</button>
 *
 * <div class="" data-showall-belongsto="1234">I’m visible</div>
 * <div class="u-hide" data-showall-belongsto="1234">I’m hidden</div>
 * <div class="u-hide" data-showall-belongsto="1234">I’m hidden too!</div>
 * ```
 *
 * Clicking the "show all" button removes the `u-hide` CSS classes
 * for all buttons having the same ID assigned as the button.
 */
export default class ShowAll {
    /**
     * Returns the class name used by the ClassLogger.
     * @protected
     */
    getClassName () {
        return 'ShowAll';
    }

    /**
     * Create a new instance.
     * @param {NavigationHandler} navigationHandler
     */
    constructor (navigationHandler) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.navigationHandler = navigationHandler;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    /**
     * Find all "show all" buttons carrying a `data-showall="<id>"` attribute.
     */
    init () {
        /** @type {HTMLButtonElement[]} */
        const showAllButtons = document.querySelectorAll('[data-showall]');

        this.logger.log(`Found ${showAllButtons.length} "show all" buttons`, { showAllButtons });

        showAllButtons.forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                this.handleClick(button);
                return false;
            });
        });
    }

    /**
     * @param {HTMLButtonElement} button
     *
     * @protected
     */
    handleClick (button) {
        button.classList.add('u-hide');

        const id = button.dataset.showall;

        if (!id) {
            this.logger.error('Button is missing a "data-showall="<id>" value. Cannot show related items.', { button });
            return;
        }

        document
            .querySelectorAll(`[data-showall-belongsto="${id}"]`)
            .forEach(element => {
                element.classList.remove('u-sr-only');
                element.classList.remove('u-hide');
            });
    }
}
