'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';

class ScrollText {
    getClassName () {
        return 'ScrollText';
    }

    /**
     * Create a new instance.
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     */
    constructor (commonMethods, navigationHandler) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;

        this.navigationHandler
            .on('ready', () => {
                this.init();
                this.initResizeListenerAndMutationObserver();
            })
            .on('render', () => this.init());
    }

    init () {
        const scrollContainers = document.querySelectorAll('[data-scrollcontainer]');

        scrollContainers.forEach((container) => {
            const scrollTexts = container.querySelectorAll('[data-scrolltext]');

            scrollTexts.forEach(scrollText => {
                this.scrollTextWhenOverflowing(scrollText, container);
            });
        });
    }

    initResizeListenerAndMutationObserver () {
        window.addEventListener('resize', () => {
            this.commonMethods.debounce(() => {
                this.init();
            }, 500)();
        });

        const observer = new MutationObserver((mutations) => {
            mutations
                .filter(mutation => mutation.target.hasAttribute('data-scrolltext'))
                .forEach(mutation => {
                    this.scrollTextWhenOverflowing(
                        mutation.target,
                        mutation.target.parentElement.closest('[data-scrollcontainer]'),
                    );
                });
        });

        document.querySelectorAll('[data-scrollcontainer]').forEach(container => {
            observer.observe(container, {
                characterData: false,
                attributes: true,
                childList: true,
                subtree: true,
            });
        });
    }

    /**
     * Animate texts in the `scrollText` element when it’s content is larger
     * than the width of the given `container` element.
     *
     * @param {HTMLElement} scrollText
     * @param {HTMLElement} container
     */
    scrollTextWhenOverflowing (scrollText, container) {
        // we need the container width without padding
        const computedStyle = getComputedStyle(container);
        let containerWidth = container.clientWidth; // width with padding
        containerWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

        const scrollTextWidth = scrollText.scrollWidth;
        const isOverflowing = scrollTextWidth > containerWidth;

        // calculate duration ("speed")
        // @see https://stackoverflow.com/a/67325362
        const timePerPixel = 28; // milliseconds
        const distance = scrollTextWidth + containerWidth;
        const animationDuration = timePerPixel * distance;

        /**
         * This variable describes the portion of the animation of scrolling the full
         * text to be invisible in comparison to the container width. A text that is
         * larger than the container container of a visible part (container width)
         * and the invisible part (text in the offset to the right). The full text
         * must be scrolled through the container and this variable describes its
         * portion. The remaining portion will then be used to scroll the text
         * from the right through the container width.
         */
        const scrollEndOfFullText = Math.round((1 - (containerWidth / (containerWidth + scrollTextWidth))) * 100) / 100;

        scrollText.getAnimations().forEach(animation => {
            animation.cancel();
        });

        if (isOverflowing) {
            this.animate(scrollText, containerWidth, scrollEndOfFullText, animationDuration);
        }
    }

    /**
     * @param {HTMLElement} scrollTextElement
     * @param {Number} containerWidth
     * @param {Number} endOfTextScroll
     * @param {Number} duration
     *
     * @returns {Animation}
     *
     * @private
     */
    animate (scrollTextElement, containerWidth, endOfTextScroll, duration) {
        /**
         * The delay is a value between 0..1 and describes the percentage
         * on how long to wait before starting the animation of the scrolltext.
         */
        const delay = parseFloat(scrollTextElement.dataset.scrolldelay || 0);

        const textScrollEnd = endOfTextScroll * (1 - delay) + delay;

        const keyframes = [
            { transform: 'translateX(0%)' },
            { transform: 'translateX(0%)', offset: delay },
            { transform: 'translateX(-100%)', offset: textScrollEnd },
            { transform: `translateX(${containerWidth}px)`, offset: textScrollEnd + 0.000001 },
            { transform: 'translateX(0%)', offset: 1 },
        ];

        return scrollTextElement.animate(keyframes, {
            duration: duration * (1 + delay),
            iterations: Infinity,
        });
    }
}

export default ScrollText;
