'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */
import ClassLogger from 'ClassLogger';

class Footer {
    getClassName () {
        return 'Footer';
    }

    /**
     * Create a new instance.
     *
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     */
    constructor (commonMethods, navigationHandler) {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.commonMethods = commonMethods;
        this.navigationHandler = navigationHandler;

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    init () {
        this.footerContent = document.querySelector('.c-footer__content');
        if (!this.footerContent) {
            return;
        }

        if (window.matchMedia('(max-width: 600px)').matches) {
            this.adjustHeight();
        }

        window.addEventListener('resize', () => {
            this.commonMethods.debounce(() => {
                this.adjustHeight();
            })();
        });
    }

    adjustHeight () {
        if (window.matchMedia('(max-width: 600px)').matches === false) {
            // this.logger.log('resetting for large viewport');
            this.footerContent.style.height = 'auto';
        } else {
            // this.logger.log('adjusting footer height');
            let heightLeft = 0;
            let heightRight = 0;

            const footerLists = this.footerContent.querySelectorAll('.l-grid__cell');
            footerLists.forEach((list) => {
                if (list.dataset.mobile === 'left') {
                    heightLeft += list.offsetHeight;
                }
                if (list.dataset.mobile === 'right') {
                    heightRight += list.offsetHeight;
                }
            });

            const computedStyle = window.getComputedStyle(this.footerContent, null);
            const paddingTop = parseInt(computedStyle.getPropertyValue('padding-top').replace('px', ''));
            const paddingBottom = parseInt(computedStyle.getPropertyValue('padding-bottom').replace('px', ''));
            const heightTotal = paddingBottom + paddingTop + Math.max(heightRight, heightLeft) + 1;
            this.footerContent.style.height = heightTotal + 'px';
        }
    }
}

export default Footer;
