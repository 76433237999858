'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('@popperjs/core').Instance} PopperInstance
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import ClassLogger from 'ClassLogger';
import { createPopper } from '@popperjs/core';

export default class Tooltip {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'ToolTip';
    }

    /**
     * @param {NavigationHandler} navigationhandler
     */
    constructor (navigationhandler) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.navigationhandler = navigationhandler;

        this.navigationhandler
            .on('ready', () => this.initTooltips())
            .on('render', () => this.initTooltips());
    }

    /**
     * Initialize the tooltips.
     *
     * @returns {this}
     */
    initTooltips () {
        this
            .initClickTooltips()
            .initHoverTooltips();
    }

    /**
     * Initializes the tooltips being shown on the click event.
     *
     * @returns {this}
     */
    initClickTooltips () {
        const tooltips = document.querySelectorAll('[data-tooltip]');

        tooltips.forEach(tooltip => {
            this.initClick(tooltip);
        });

        return this;
    }

    /**
     * Initialize the tooltip for the given `tooltip` element.
     *
     * @param {Element} tooltip
     */
    initClick (tooltip) {
        const tooltipContents = document.querySelectorAll('[data-tooltipcontent]');
        const tooltipButton = tooltip.querySelector('button');
        const tooltipContent = tooltip.querySelector('[data-tooltipcontent]');
        const popperInstance = this.createPopperFor(tooltipButton, tooltipContent);

        tooltipButton.addEventListener('click', event => {
            event.preventDefault();

            if (tooltipContent.hasAttribute('data-show')) {
                tooltipContent.removeAttribute('data-show');
            } else {
                // hide all other tooltips first
                tooltipContents.forEach(tooltip => {
                    if (tooltip.hasAttribute('data-show')) {
                        tooltip.removeAttribute('data-show');
                    }
                });
                // show clicked one
                tooltipContent.setAttribute('data-show', '');
                popperInstance.update();
            }
        });
    }

    /**
     * Initialize the tooltips being shown on the hover event.
     *
     * @returns {this}
     */
    initHoverTooltips () {
        document.querySelectorAll('[data-tooltip-hover]').forEach(tooltip => {
            const content = tooltip.querySelector('[data-tooltipcontent]');

            if (!content) {
                throw new Error(
                    'Missing element with "data-tooltipcontent" attribute inside the tooltip wrapper', { tooltip },
                );
            }

            const popperInstance = this.createPopperFor(tooltip, content);

            tooltip.addEventListener('mouseenter', () => {
                if (!content.hasAttribute('data-show')) {
                    content.setAttribute('data-show', '');
                    popperInstance.update();
                }
            });

            tooltip.addEventListener('mouseleave', () => {
                /**
                 * Leaving the tooltip area should not immediately close the tooltip’s content,
                 * because there is space between the tooltip’s target and the shown content.
                 * The timeout below keeps the tooltip’s content open for a period of time.
                 */
                const timeout = setTimeout(() => {
                    if (content.hasAttribute('data-show')) {
                        clearTimeout(timeout);
                        content.style.animation = 'fadeOut 0.1s linear';

                        // reset the animation after the animation has finished
                        setTimeout(() => {
                            content.style.animation = '';
                            content.removeAttribute('data-show');
                            popperInstance.update();
                        }, 100);
                    }
                }, 200);

                content.addEventListener('mouseenter', () => {
                    if (timeout) {
                        clearTimeout(timeout);
                    }
                }, { once: true });
            });
        });

        return this;
    }

    /**
     * Returns a popper instance for the given `target` showing the `content` when activated.
     *
     * @param {Element} target
     * @param {Element} content
     *
     * @returns {PopperInstance}
     */
    createPopperFor (target, content) {
        const placement = content.getAttribute('data-tooltip-placement');
        const offsetSetting = content.getAttribute('data-tooltip-offset');

        return createPopper(target, content, {
            placement: placement || 'top',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: () => {
                            if (offsetSetting && offsetSetting === 'player') {
                                return [0, 15];
                            }
                            return [0, 3]; // unit=px, values=[x, y]
                        },
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        padding: 5,
                    },
                },
            ],
        });
    }
}
