'use strict';

/**
 * @typedef {import("../antenne-frontend").ApiResponsePayload} ApiResponsePayload
 */

export class ApiError extends Error {
    /**
     * Create a new instance and propagate properties
     *
     * @param {string} message
     * @param {string} code
     * @param {any} details
     * @param {number|undefined} statusCode
     * @param {string|undefined} requestId
     */
    constructor (message, code, details, statusCode, requestId, overlay = {}) {
        super(`${code}: ${message}`);

        this.code = code;
        this.details = details;
        this.requestId = requestId;
        this.statusCode = statusCode;
        this.overlay = overlay;
        this.hasOverlay = Object.keys(overlay).length !== 0;
    }

    /**
     * Returns an ApiError instance from the given `error` and `response`.
     *
     * @param {ApiResponsePayload} payload
     * @param {Response} response
     *
     * @returns {ApiError}
     */
    static from (payload, response = {}) {
        if (!payload || !payload.error) {
            throw new Error('Invalid "payload" parameter. Cannot create an API error instance.', { payload });
        }

        return new this(
            payload.error.message,
            payload.error.code,
            payload.error.details || {},
            response.status || 0,
            payload.request_id,
            payload.error.overlay || {},
        );
    }
}

export class ApiRequestTimeoutError extends ApiError {
    constructor (message) {
        super(message, 'api-request-timeout');
    }
}
