'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('../antenne-frontend')}
 * @typedef {import('mitt').Emitter} EventEmitter
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./TrackingService').default} TrackingService
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

// import Videojs from 'video.js';
import ClassLogger from 'ClassLogger';
// import SeekButton from './videoplayer/SeekButton';
// import AirplayButton from './videoplayer/AirplayButton';
// import FullscreenToggle from './videoplayer/FullscreenToggle';

export default class VideoPlayer {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'VideoPlayer';
    }

    /**
     * @param {TrackingService} trackingService
     * @param {EventEmitter} eventEmitter
     * @param {NavigationHandler} navigationHandler
     * @param {CommonMethods} commonMethods
     */
    constructor (trackingService, eventEmitter, navigationHandler, commonMethods) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        /** @type {HTMLVideoElement[]} */
        this.videoTags = [];

        /** @type {Videojs.Player[]} */
        this.players = [];

        this.eventEmitter = eventEmitter;
        this.commonMethods = commonMethods;
        this.trackingService = trackingService;
        this.navigationHandler = navigationHandler;

        this.navigationHandler
            .on('ready', () => this.pickupVideoElements())
            .on('render', () => this.pickupVideoElements());
    }

    /**
     * Initialize click listeners for all play buttons.
     *
     * @returns {this}
     */
    pickupVideoElements () {
        this.videoTags = Array.from(document.querySelectorAll('video'));

        if (this.videoTags.length === 0) {
            return this;
        }

        this.logger.log(`Found ${this.videoTags.length} video elements on this page:`, this.videoTags);
        throw new Error('Video player is removed and currently not supported on this page.');

        // return this.initVideojs();
    }

    // /**
    //  * Tba.
    //  *
    //  * @returns {this}
    //  */
    // initVideojs () {
    //     this.logger.log('Initializing video players', this.videoTags);

    //     Videojs.registerComponent('SeekBack', SeekButton);
    //     Videojs.registerComponent('SeekForward', SeekButton);
    //     Videojs.registerComponent('AirplayButton', AirplayButton);
    //     Videojs.registerComponent('FullscreenToggle', FullscreenToggle);

    //     this.players = this.videoTags.map(video => {
    //         const player = Videojs(video, {
    //             commonMethods: this.commonMethods,

    //             // playbackRates: [1, 1.5, 2],
    //             responsive: true,
    //             controls: true,
    //             language: 'de',
    //             preload: 'auto',

    //             controlBar: {
    //                 subsCapsButton: false,
    //                 chaptersButton: false,
    //                 audioTrackButton: false,
    //                 descriptionsButton: false,
    //                 remainingTimeDisplay: false,
    //                 pictureInPictureToggle: false,
    //                 playbackRateMenuButton: false,

    //                 currentTimeDisplay: true,
    //                 timeDivider: true,
    //                 durationDisplay: true,

    //                 seekBack: {
    //                     direction: 'back',
    //                     seconds: 10,
    //                 },
    //                 seekForward: {
    //                     direction: 'forward',
    //                     seconds: 10,
    //                 },
    //                 airplayButton: true,
    //                 volumePanel: {
    //                     inline: false,
    //                 },
    //                 fullscreenToggle: true,
    //             },
    //             userActions: {
    //                 hotkeys (event) {
    //                     switch (event.key) {
    //                         case 'ArrowRight':
    //                             return this.trigger('seek:forward');

    //                         case 'ArrowLeft':
    //                             return this.trigger('seek:back');
    //                     }
    //                 },
    //             },
    //         });

    //         player.on('play', async (event) => {
    //             this.pauseVideos(event);
    //             this.eventEmitter.emit('video:play');
    //             this.trackMediaEvent('start');
    //             await this.notifyNativeToStopMedia();
    //         });

    //         player.on('seeked', (event) => {
    //             this.trackMediaEvent('seek');
    //         });

    //         player.on('pause', (event) => {
    //             this.eventEmitter.emit('video:pause');
    //             this.trackMediaEvent('pause');
    //         });

    //         player.on('ended', (event) => {
    //             this.trackMediaEvent('complete');
    //         });

    //         player.on('abort', () => {
    //             this.trackMediaEvent('cancel');
    //         });

    //         this.eventEmitter.on('audio:play', () => player.pause());

    //         return player;
    //     });

    //     return this;
    // }

    // /**
    //  * Send a `media.stop` event to the native app.
    //  */
    // async notifyNativeToStopMedia () {
    //     try {
    //         await window.nativeJsBridge.callHandlerIfWebview('media.stop', {});
    //     } catch (error) {
    //         this.logger.error('Received error while sending "media.stop" to native app', error);
    //     }
    // }

    // /**
    //  * Pause all player’s except the event’s target.
    //  *
    //  * @param {Event} event
    //  */
    // pauseVideos (event = {}) {
    //     this.players
    //         .filter(player => player.el() !== event.target)
    //         .forEach(player => player.pause());
    // }

    // /**
    //  * Track a media event with the given `type` and `occurredon` datetime.
    //  *
    //  * @param {'start' | 'pause' | 'seek' | 'cancel' | 'complete'} type
    //  *
    //  * @returns {this}
    //  */
    // trackMediaEvent (type, occurredon = new Date().toISOString()) {
    //     // this.trackEvent(`fix:media:${type}`, {
    //     //     // TODO: how do we create a metadata item

    //     //     // ...this.currentMetadataItem,
    //     //     occurredon,
    //     // });

    //     return this;
    // }

    // /**
    //  * Track an event for the Quantyoo datastream.
    //  *
    //  * @param {string} type Streamtype
    //  * @param {object} data Payload object
    //  *
    //  * @returns {this}
    //  */
    // trackEvent (type, data) {
    //     this.logger.log(`Tracking "${type}" event`, { type, data });

    //     try {
    //         this.trackingService.track(type, data);
    //     } catch (error) {
    //         this.logger.error('Failed to track event', { type, data, error });
    //     }

    //     return this;
    // }
}
