'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */

import { annotate } from 'rough-notation';
import ClassLogger from 'ClassLogger';

class RoughNotations {
    getClassName () {
        return 'RoughNotations';
    }

    /**
     * Create a new instance.
     *
     * @param {NavigationHandler} navigationHandler
     */
    constructor (navigationHandler) {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.navigationHandler = navigationHandler;

        if (document.documentElement.classList.contains('template--rockantenne') === false) {
            // RoughNotation only for 'rockantenne' template variation
            return;
        }

        this.navigationHandler
            .on('ready', () => this.init())
            .on('render', () => this.init());
    }

    init () {
        this.headerHover();

        const notationElements = document.querySelectorAll('[data-notation]');
        if (notationElements.length === 0) {
            return;
        }
        notationElements.forEach((element) => {
            const type = element.dataset.notation;
            if (type === 'underline') {
                // Creating another child-div here so notation svg is added *inside* the .u-separator element, which
                // uses position-relative so that the notation svg is always in the correct place place.
                // this is important when fading in peviously hidden elements (e.g. "load more cards")
                const notationWrapper = document.createElement('div');
                element.appendChild(notationWrapper);
                const annotation = annotate(notationWrapper, {
                    type,
                    color: '#E6001E',
                    animate: true,
                    iterations: 2,
                    strokeWidth: 6,
                    padding: 0,
                });
                annotation.show();
            }
        });
    }

    headerHover () {
        const nav = document.querySelector('.c-mainnav');
        if (!nav) {
            return;
        }
        let navItems = nav.querySelectorAll('a');
        const menuButton = document.querySelector('.c-headeractions__menu');
        navItems = [...navItems, menuButton];

        navItems.forEach((navItem) => {
            const annotation = annotate(navItem, {
                type: 'underline',
                color: '#E6001E',
                animate: true,
                animationDuration: 400,
                iterations: 2,
                strokeWidth: 6,
                padding: 0,
            });

            navItem.addEventListener('mouseover', () => {
                // navItem.setAttribute('data-notation', 'underline');
                annotation.show();
            });

            navItem.addEventListener('mouseout', () => {
                // navItem.removeAttribute('data-notation');
                annotation.hide();
            });

            if (navItem.dataset.navhandlerlink) {
                // If menu button is clicked, hide as mouseout is sometimes not catched
                navItem.addEventListener('click', () => {
                    annotation.hide();
                });
            }
        });
    }
}

export default RoughNotations;
