'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 * @typedef {import('./TrackingService').default} TrackingService
 */

import ClassLogger from 'ClassLogger';

export default class WebShareUI {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'WebShareUi';
    }

    /**
     * @param {NavigationHandler} navigationHandler
     * @param {TrackingService} trackingService
    */
    constructor (navigationHandler, trackingService) {
        const self = this;
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.navigationHandler = navigationHandler;
        this.trackingService = trackingService;

        const android = navigator.userAgent.match(/Android/i);
        const ios = navigator.userAgent.match(/iPhone|iPad|iPod/i);
        // on those two support "mobile deep links", so HTTP based fallback for all others.
        this.isDesktop = !(ios || android);

        this.shareUrls = {
            whatsapp: payload => {
                return (this.isDesktop ? 'https://api.whatsapp.com/send?text=' : 'whatsapp://send?text=') + payload;
            },
            telegram: payload => {
                return (this.isDesktop
                    ? 'https://telegram.me/share/msg?url=' +
                    this.url + '&text='
                    : 'tg://msg?text=') + payload;
            },
            facebook: (payload, fbid, url) => {
                return !fbid
                    ? ''
                    : (this.isDesktop
                            ? 'https://www.facebook.com/dialog/share?app_id=' + fbid +
                '&display=popup&href=' + url + '&redirect_uri=' + encodeURIComponent(location.href) + '&quote='
                            : 'fb-messenger://share/?message=') + payload;
            },
            email: (payload, title) => {
                return 'mailto:?subject=' + title + '&body=' + payload;
            },
            sms: payload => {
                return 'sms:?body=' + payload;
            }, // sms on ios 'sms:;body='+payload, on Android 'sms:?body='+payload
        };

        this.navigationHandler
            .on('ready', () => this.initShareButton())
            .on('render', () => this.initShareButton());

        navigator.share = navigator.share || (function () {
            if (navigator.share) {
                return navigator.share;
            }

            /* async */
            return data => self.show(data);
        }());
    }

    /* async */
    _init () {
        if (this._initialized) return Promise.resolve();
        this._initialized = true;

        const el = document.querySelector('.c-webshareui');

        this.$root = el.querySelector('.web-share');
        this.$whatsapp = el.querySelector('.web-share-whatsapp');
        this.$facebook = el.querySelector('.web-share-facebook');
        this.$telegram = el.querySelector('.web-share-telegram');
        this.$email = el.querySelector('.web-share-email');
        this.$sms = el.querySelector('.web-share-sms');
        this.$copy = el.querySelector('.web-share-copy');
        this.$copy.onclick = (e) => {
            e.preventDefault();
            this._copy();
        };
        this.$root.onclick = () => this._hide();
        this.$root.classList.toggle('desktop', this.isDesktop);

        document.body.appendChild(el);
        return Promise.resolve();
    }

    _setPayload (payloadObj) {
        let payload = payloadObj.text + ' \n' + payloadObj.url;
        let title = payloadObj.title;
        const facebookId = payloadObj.facebookId || '285799303502893';
        this.url = payloadObj.url;
        payload = encodeURIComponent(payload);
        title = encodeURIComponent(title);
        this.$whatsapp.href = this.shareUrls.whatsapp(payload);
        this.$facebook.href = this.shareUrls.facebook(payload, facebookId, this.url);
        this.$telegram.href = this.shareUrls.telegram(payload);
        this.$email.href = this.shareUrls.email(payload, title);
        this.$sms.href = this.shareUrls.sms(payload);
    }

    _copy () {
        // A <span> contains the text to copy
        const span = document.createElement('span');
        span.textContent = this.url;
        span.style.whiteSpace = 'pre'; // Preserve consecutive spaces and newlines

        // Paint the span outside the viewport
        span.style.position = 'absolute';
        span.style.left = '-9999px';
        span.style.top = '-9999px';

        const win = window;
        const selection = win.getSelection();
        win.document.body.appendChild(span);

        const range = win.document.createRange();
        selection.removeAllRanges();
        range.selectNode(span);
        selection.addRange(range);

        let success = false;
        try {
            success = win.document.execCommand('copy');
        } catch (err) {}

        selection.removeAllRanges();
        span.remove();

        return success;
    }

    /* async */
    show (payloadObj) {
        return this._init().then(() => {
            clearTimeout(this._hideTimer);
            this._setPayload(payloadObj);
            this.$root.style.display = 'flex';
            // this.$root.offsetWidth; // style update
            this.$root.style.background = 'rgba(0,0,0,.4)';
            document.querySelectorAll('.web-share-container').forEach(el => {
                el.style.transform = 'translateY(0)';
                el.style.opacity = 1;
            });
        });
    }

    _hide () {
        this.$root.style.background = null;
        document.querySelectorAll('.web-share-container').forEach(el => {
            el.style.transform = null;
            el.style.opacity = null;
        });
        this._hideTimer = setTimeout(() => {
            this.$root.style.display = null;
        }, 400);
    }

    getDeeplink (url, fallbackToStore) {
        if (url.startsWith('/deeplink/')) {
            return url;
        }
        const urlObj = new URL(url, window.location.origin);

        // prefix path with `/deeplink`
        urlObj.pathname = '/deeplink' + urlObj.pathname;

        // add optional fallback to query string
        if (fallbackToStore) {
            urlObj.searchParams.set('deeplink_fallback', 'store');
        }

        return urlObj.toString();
    }

    initShareButton () {
        const shareButtons = document.querySelectorAll('[data-sharebutton]');
        shareButtons.forEach(shareButton => {
            shareButton.addEventListener('click', () => {
                const url = this.getDeeplink(shareButton.dataset.shareurl, false);
                navigator.share({
                    title: shareButton.dataset.sharetitle,
                    text: shareButton.dataset.sharetext,
                    url: url,
                })
                    .then(() => {
                        this.logger.log('Successful share');
                        this.trackingService.track('share', {
                            identifier: url,
                            title: shareButton.dataset.sharetitle,
                            url: url,
                        });
                    })
                    .catch(error => this.logger.log('Error sharing', error));
            });
        });

        const shareLists = document.querySelectorAll('[data-sharelinklist]');
        shareLists.forEach(shareList => {
            const data = JSON.parse(shareList.dataset.sharelinklist);
            shareList.querySelectorAll('a').forEach(shareLink => {
                shareLink.addEventListener('click', () => {
                    this.trackingService.track('share', {
                        identifier: data.link,
                        title: data.title,
                        url: data.link,
                    });
                });
            });
        });
    }
}
