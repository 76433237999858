export function OldieAntenneColorRotation (navigationHandler) {
    if (document.documentElement.classList.contains('template--oldieantenne') === true) {
        const colors = ['#cd007b', '#e2001a', '#ee7f00', '#1fa22e', '#00519e']; // yellow '#f6c900' -> bad contrast
        navigationHandler.on('render', () => {
            document.documentElement.style.setProperty(
                '--antenne-color-secondary',
                colors[Math.floor(Math.random() * colors.length)],
            );
        });
        document.documentElement.style.setProperty(
            '--antenne-color-secondary',
            colors[Math.floor(Math.random() * colors.length)],
        );
    }
}
